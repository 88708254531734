import React from 'react'
import './Campain.css'

const Novedades = () => {
  return (
    <div>
      <div className='d-flex justify-content-evenly align-items-center mt-3 mx-5'>
          <button className='regla_btn_gral regla_btn_selected'>
            <span className='m-2'>Regla</span>
          </button>
          <svg className='regla_btn_selected' xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
            <circle cx="12.5" cy="12.5" r="12.5" fill="#2BAE7E"/>
            <path d="M16.25 12.1122C16.2508 12.2445 16.2245 12.3757 16.1727 12.4982C16.1209 12.6207 16.0446 12.7322 15.9481 12.8261L11.7843 16.8484C11.5883 17.0378 11.3224 17.1442 11.0452 17.1442C10.768 17.1442 10.5022 17.0378 10.3061 16.8484C10.1101 16.6591 10 16.4023 10 16.1345C10 15.8667 10.1101 15.6099 10.3061 15.4205L13.7413 12.1122L10.3165 8.80382C10.146 8.61146 10.0569 8.36401 10.067 8.11093C10.0771 7.85785 10.1857 7.61778 10.3711 7.43869C10.5565 7.2596 10.805 7.15469 11.067 7.14492C11.329 7.13514 11.5852 7.22122 11.7843 7.38596L15.9481 11.4083C16.1404 11.5956 16.2488 11.8484 16.25 12.1122Z" fill="white"/>
          </svg>
          <button className='regla_btn_gral'>
            <span className='m-2'>Configuración</span>
          </button>
          <svg className='regla' xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
            <circle cx="12.5" cy="12.5" r="12.5" fill="#2BAE7E"/>
            <path d="M16.25 12.1122C16.2508 12.2445 16.2245 12.3757 16.1727 12.4982C16.1209 12.6207 16.0446 12.7322 15.9481 12.8261L11.7843 16.8484C11.5883 17.0378 11.3224 17.1442 11.0452 17.1442C10.768 17.1442 10.5022 17.0378 10.3061 16.8484C10.1101 16.6591 10 16.4023 10 16.1345C10 15.8667 10.1101 15.6099 10.3061 15.4205L13.7413 12.1122L10.3165 8.80382C10.146 8.61146 10.0569 8.36401 10.067 8.11093C10.0771 7.85785 10.1857 7.61778 10.3711 7.43869C10.5565 7.2596 10.805 7.15469 11.067 7.14492C11.329 7.13514 11.5852 7.22122 11.7843 7.38596L15.9481 11.4083C16.1404 11.5956 16.2488 11.8484 16.25 12.1122Z" fill="white"/>
          </svg>
          <button className='regla_btn_gral'>
            <span className='m-2'>Template</span>
          </button>
          <svg className='regla' xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
            <circle cx="12.5" cy="12.5" r="12.5" fill="#2BAE7E"/>
            <path d="M16.25 12.1122C16.2508 12.2445 16.2245 12.3757 16.1727 12.4982C16.1209 12.6207 16.0446 12.7322 15.9481 12.8261L11.7843 16.8484C11.5883 17.0378 11.3224 17.1442 11.0452 17.1442C10.768 17.1442 10.5022 17.0378 10.3061 16.8484C10.1101 16.6591 10 16.4023 10 16.1345C10 15.8667 10.1101 15.6099 10.3061 15.4205L13.7413 12.1122L10.3165 8.80382C10.146 8.61146 10.0569 8.36401 10.067 8.11093C10.0771 7.85785 10.1857 7.61778 10.3711 7.43869C10.5565 7.2596 10.805 7.15469 11.067 7.14492C11.329 7.13514 11.5852 7.22122 11.7843 7.38596L15.9481 11.4083C16.1404 11.5956 16.2488 11.8484 16.25 12.1122Z" fill="white"/>
          </svg>
          <button className='regla_btn_gral'>
            <span className='m-2'>Editor</span>
          </button>
          <svg className='regla' xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
            <circle cx="12.5" cy="12.5" r="12.5" fill="#2BAE7E"/>
            <path d="M16.25 12.1122C16.2508 12.2445 16.2245 12.3757 16.1727 12.4982C16.1209 12.6207 16.0446 12.7322 15.9481 12.8261L11.7843 16.8484C11.5883 17.0378 11.3224 17.1442 11.0452 17.1442C10.768 17.1442 10.5022 17.0378 10.3061 16.8484C10.1101 16.6591 10 16.4023 10 16.1345C10 15.8667 10.1101 15.6099 10.3061 15.4205L13.7413 12.1122L10.3165 8.80382C10.146 8.61146 10.0569 8.36401 10.067 8.11093C10.0771 7.85785 10.1857 7.61778 10.3711 7.43869C10.5565 7.2596 10.805 7.15469 11.067 7.14492C11.329 7.13514 11.5852 7.22122 11.7843 7.38596L15.9481 11.4083C16.1404 11.5956 16.2488 11.8484 16.25 12.1122Z" fill="white"/>
          </svg>
          <button className='regla_btn_gral'>
            <span className='m-2'>Resumen</span>
          </button>
      </div>
      <div className='regla_frame d-flex justify-content-center mt-3 mx-5'> 
          <div className='col_lf'>
            <h1>Tipo de Campaña</h1>
            <div className='regla_menu_lat'>
              <h3>ÚNICA VEZ</h3>
              <span>Crear Campaña</span>
              <ul>
                <h3>AUTOMATIZADAS</h3>
                <li className='li_selected'>Welcome Kit</li>
                <li>Post Venta</li>
                <li>Carro Abandonado</li>
                <li>Remarketing</li>
                <li>Relacionamiento</li>
                <li>Recupero de Clientes</li>
                <li>Fidelidad</li>
                <li>Otras Campañas</li>
              </ul>
            </div>
          </div>
          <div className='col_rt d-flex flex-wrap justify-content-around align-content-evenly'>
            <div className='regla_cards'>
              <div className='d-flex justify-content-between align-items-center mb-3'>
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                  <path d="M14 27.6391C21.5327 27.6391 27.6391 21.5327 27.6391 14C27.6391 6.46738 21.5327 0.360962 14 0.360962C6.46738 0.360962 0.360962 6.46738 0.360962 14C0.360962 21.5327 6.46738 27.6391 14 27.6391Z" fill="#10A36C"/>
                  <path d="M13.6073 4.05051C12.18 4.28994 10.9118 5.1144 10.1224 6.31624C9.86147 6.71093 9.79132 6.84842 9.62204 7.26042C9.16749 8.39164 9.13943 9.74454 9.54113 10.8898C9.86194 11.7989 10.468 12.6551 11.19 13.2224C11.3453 13.3459 11.465 13.4586 11.458 13.4726C11.4472 13.4866 11.3416 13.5362 11.2218 13.5783C10.873 13.7087 10.186 14.0436 9.82312 14.262C8.62175 14.9845 7.57516 16.0166 6.80682 17.2254C6.54588 17.6379 6.16194 18.4268 5.98564 18.9169C5.83038 19.3434 5.65782 20.0093 5.57318 20.5027C5.50257 20.9006 5.42868 21.8593 5.46048 21.9402C5.48152 21.9968 5.54512 22.0001 6.16148 21.993L6.84143 21.9823L6.84844 21.8238C6.94338 20.0551 7.44048 18.6737 8.45527 17.3311C8.73351 16.9682 9.43825 16.2635 9.80114 15.9852C11.1508 14.9634 12.7716 14.3924 14.3359 14.3892C15.2062 14.3854 15.9675 14.2166 16.7073 13.8607C17.7399 13.3604 18.5573 12.564 19.0787 11.5459C19.6497 10.4287 19.7975 9.13941 19.4912 7.90623C19.3434 7.32121 19.1353 6.85263 18.7832 6.31718C17.9868 5.10505 16.7115 4.28059 15.2632 4.04817C14.8723 3.98364 13.995 3.98364 13.6073 4.05051ZM15.0696 5.44222C16.6128 5.66435 17.9133 6.95037 18.1879 8.52866C18.4133 9.82544 17.9414 11.1573 16.9477 12.0313C15.6191 13.1976 13.6283 13.2893 12.2296 12.2464C11.2569 11.5206 10.7215 10.5161 10.6756 9.33254C10.6438 8.4833 10.8309 7.79259 11.2925 7.09861C12.1309 5.82662 13.5156 5.22055 15.0696 5.44222Z" fill="white"/>
                  <path d="M19.1217 16.1503V17.6303H17.6416H16.1615L16.1685 18.3243L16.1793 19.022L17.6486 19.0328L19.1217 19.0398L19.1287 20.5129L19.1395 21.9822L19.8372 21.993L20.5312 22V20.5199V19.0398H22.0112H23.4913V18.3351V17.6303H22.0112H20.5312V16.1503V14.6702H19.8264H19.1217V16.1503Z" fill="white"/>
                  <defs>
                  <clipPath id="clip0_8552_508">
                  <rect width="28" height="28" fill="white"/>
                  </clipPath>
                  </defs>
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
                  <path d="M12.7006 5.23373C11.6922 4.24451 10.3835 3.70225 8.9951 3.70353C6.49645 3.70583 4.33935 5.50064 3.74377 7.99032C3.70042 8.17156 3.54629 8.29954 3.36794 8.29954H1.51945C1.27758 8.29954 1.09384 8.0695 1.13858 7.82047C1.83655 3.93752 5.09074 1 9 1C11.1435 1 13.09 1.88324 14.5263 3.32115L15.6784 2.11419C16.1661 1.60326 17 1.96513 17 2.68771V7.21813C17 7.66607 16.6534 8.02919 16.2258 8.02919H11.9013C11.2116 8.02919 10.8662 7.15557 11.3539 6.64461L12.7006 5.23373ZM1.77419 10.7327H6.09868C6.78842 10.7327 7.13384 11.6063 6.64613 12.1173L5.29935 13.5282C6.30781 14.5175 7.61661 15.0597 9.00503 15.0584C11.5024 15.056 13.6604 13.2624 14.2562 10.7717C14.2996 10.5904 14.4537 10.4624 14.6321 10.4624H16.4806C16.7225 10.4624 16.9062 10.6925 16.8615 10.9415C16.1635 14.8244 12.9093 17.7619 9 17.7619C6.85652 17.7619 4.90997 16.8787 3.47371 15.4408L2.32161 16.6477C1.8339 17.1586 1 16.7968 1 16.0742V11.5438C1 11.0958 1.34661 10.7327 1.77419 10.7327Z" fill="#444444" stroke="white"/>
                </svg>
              </div>
              <h1 className='m-0'>Registro nuevo: 1er. paso</h1>
              <p className='m-0 mt-1'>Envía el primer disparo de una serie de bienvenida a nuevos registros luego de su suscripción a tu newsletter.</p>
            </div>
            <div className='regla_cards'>
              <div className='d-flex justify-content-between align-items-center mb-3'>
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                  <path d="M14 27.6391C21.5327 27.6391 27.6391 21.5327 27.6391 14C27.6391 6.46738 21.5327 0.360962 14 0.360962C6.46738 0.360962 0.360962 6.46738 0.360962 14C0.360962 21.5327 6.46738 27.6391 14 27.6391Z" fill="#10A36C"/>
                  <path d="M13.6073 4.05051C12.18 4.28994 10.9118 5.1144 10.1224 6.31624C9.86147 6.71093 9.79132 6.84842 9.62204 7.26042C9.16749 8.39164 9.13943 9.74454 9.54113 10.8898C9.86194 11.7989 10.468 12.6551 11.19 13.2224C11.3453 13.3459 11.465 13.4586 11.458 13.4726C11.4472 13.4866 11.3416 13.5362 11.2218 13.5783C10.873 13.7087 10.186 14.0436 9.82312 14.262C8.62175 14.9845 7.57516 16.0166 6.80682 17.2254C6.54588 17.6379 6.16194 18.4268 5.98564 18.9169C5.83038 19.3434 5.65782 20.0093 5.57318 20.5027C5.50257 20.9006 5.42868 21.8593 5.46048 21.9402C5.48152 21.9968 5.54512 22.0001 6.16148 21.993L6.84143 21.9823L6.84844 21.8238C6.94338 20.0551 7.44048 18.6737 8.45527 17.3311C8.73351 16.9682 9.43825 16.2635 9.80114 15.9852C11.1508 14.9634 12.7716 14.3924 14.3359 14.3892C15.2062 14.3854 15.9675 14.2166 16.7073 13.8607C17.7399 13.3604 18.5573 12.564 19.0787 11.5459C19.6497 10.4287 19.7975 9.13941 19.4912 7.90623C19.3434 7.32121 19.1353 6.85263 18.7832 6.31718C17.9868 5.10505 16.7115 4.28059 15.2632 4.04817C14.8723 3.98364 13.995 3.98364 13.6073 4.05051ZM15.0696 5.44222C16.6128 5.66435 17.9133 6.95037 18.1879 8.52866C18.4133 9.82544 17.9414 11.1573 16.9477 12.0313C15.6191 13.1976 13.6283 13.2893 12.2296 12.2464C11.2569 11.5206 10.7215 10.5161 10.6756 9.33254C10.6438 8.4833 10.8309 7.79259 11.2925 7.09861C12.1309 5.82662 13.5156 5.22055 15.0696 5.44222Z" fill="white"/>
                  <path d="M19.1217 16.1503V17.6303H17.6416H16.1615L16.1685 18.3243L16.1793 19.022L17.6486 19.0328L19.1217 19.0398L19.1287 20.5129L19.1395 21.9822L19.8372 21.993L20.5312 22V20.5199V19.0398H22.0112H23.4913V18.3351V17.6303H22.0112H20.5312V16.1503V14.6702H19.8264H19.1217V16.1503Z" fill="white"/>
                  <defs>
                  <clipPath id="clip0_8552_508">
                  <rect width="28" height="28" fill="white"/>
                  </clipPath>
                  </defs>
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
                  <path d="M12.7006 5.23373C11.6922 4.24451 10.3835 3.70225 8.9951 3.70353C6.49645 3.70583 4.33935 5.50064 3.74377 7.99032C3.70042 8.17156 3.54629 8.29954 3.36794 8.29954H1.51945C1.27758 8.29954 1.09384 8.0695 1.13858 7.82047C1.83655 3.93752 5.09074 1 9 1C11.1435 1 13.09 1.88324 14.5263 3.32115L15.6784 2.11419C16.1661 1.60326 17 1.96513 17 2.68771V7.21813C17 7.66607 16.6534 8.02919 16.2258 8.02919H11.9013C11.2116 8.02919 10.8662 7.15557 11.3539 6.64461L12.7006 5.23373ZM1.77419 10.7327H6.09868C6.78842 10.7327 7.13384 11.6063 6.64613 12.1173L5.29935 13.5282C6.30781 14.5175 7.61661 15.0597 9.00503 15.0584C11.5024 15.056 13.6604 13.2624 14.2562 10.7717C14.2996 10.5904 14.4537 10.4624 14.6321 10.4624H16.4806C16.7225 10.4624 16.9062 10.6925 16.8615 10.9415C16.1635 14.8244 12.9093 17.7619 9 17.7619C6.85652 17.7619 4.90997 16.8787 3.47371 15.4408L2.32161 16.6477C1.8339 17.1586 1 16.7968 1 16.0742V11.5438C1 11.0958 1.34661 10.7327 1.77419 10.7327Z" fill="#444444" stroke="white"/>
                </svg>
              </div>
              <h1 className='m-0'>Registro nuevo: 2do. paso</h1>
              <p className='m-0 mt-1'>Envía el segundo disparo de una serie de bienvenida a nuevos registros luego de su suscripción a tu newsletter.</p>
            </div>
            <div className='regla_cards'>
              <div className='d-flex justify-content-between align-items-center mb-3'>
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                  <path d="M14 27.6391C21.5327 27.6391 27.6391 21.5327 27.6391 14C27.6391 6.46738 21.5327 0.360962 14 0.360962C6.46738 0.360962 0.360962 6.46738 0.360962 14C0.360962 21.5327 6.46738 27.6391 14 27.6391Z" fill="#10A36C"/>
                  <path d="M13.6073 4.05051C12.18 4.28994 10.9118 5.1144 10.1224 6.31624C9.86147 6.71093 9.79132 6.84842 9.62204 7.26042C9.16749 8.39164 9.13943 9.74454 9.54113 10.8898C9.86194 11.7989 10.468 12.6551 11.19 13.2224C11.3453 13.3459 11.465 13.4586 11.458 13.4726C11.4472 13.4866 11.3416 13.5362 11.2218 13.5783C10.873 13.7087 10.186 14.0436 9.82312 14.262C8.62175 14.9845 7.57516 16.0166 6.80682 17.2254C6.54588 17.6379 6.16194 18.4268 5.98564 18.9169C5.83038 19.3434 5.65782 20.0093 5.57318 20.5027C5.50257 20.9006 5.42868 21.8593 5.46048 21.9402C5.48152 21.9968 5.54512 22.0001 6.16148 21.993L6.84143 21.9823L6.84844 21.8238C6.94338 20.0551 7.44048 18.6737 8.45527 17.3311C8.73351 16.9682 9.43825 16.2635 9.80114 15.9852C11.1508 14.9634 12.7716 14.3924 14.3359 14.3892C15.2062 14.3854 15.9675 14.2166 16.7073 13.8607C17.7399 13.3604 18.5573 12.564 19.0787 11.5459C19.6497 10.4287 19.7975 9.13941 19.4912 7.90623C19.3434 7.32121 19.1353 6.85263 18.7832 6.31718C17.9868 5.10505 16.7115 4.28059 15.2632 4.04817C14.8723 3.98364 13.995 3.98364 13.6073 4.05051ZM15.0696 5.44222C16.6128 5.66435 17.9133 6.95037 18.1879 8.52866C18.4133 9.82544 17.9414 11.1573 16.9477 12.0313C15.6191 13.1976 13.6283 13.2893 12.2296 12.2464C11.2569 11.5206 10.7215 10.5161 10.6756 9.33254C10.6438 8.4833 10.8309 7.79259 11.2925 7.09861C12.1309 5.82662 13.5156 5.22055 15.0696 5.44222Z" fill="white"/>
                  <path d="M19.1217 16.1503V17.6303H17.6416H16.1615L16.1685 18.3243L16.1793 19.022L17.6486 19.0328L19.1217 19.0398L19.1287 20.5129L19.1395 21.9822L19.8372 21.993L20.5312 22V20.5199V19.0398H22.0112H23.4913V18.3351V17.6303H22.0112H20.5312V16.1503V14.6702H19.8264H19.1217V16.1503Z" fill="white"/>
                  <defs>
                  <clipPath id="clip0_8552_508">
                  <rect width="28" height="28" fill="white"/>
                  </clipPath>
                  </defs>
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
                  <path d="M12.7006 5.23373C11.6922 4.24451 10.3835 3.70225 8.9951 3.70353C6.49645 3.70583 4.33935 5.50064 3.74377 7.99032C3.70042 8.17156 3.54629 8.29954 3.36794 8.29954H1.51945C1.27758 8.29954 1.09384 8.0695 1.13858 7.82047C1.83655 3.93752 5.09074 1 9 1C11.1435 1 13.09 1.88324 14.5263 3.32115L15.6784 2.11419C16.1661 1.60326 17 1.96513 17 2.68771V7.21813C17 7.66607 16.6534 8.02919 16.2258 8.02919H11.9013C11.2116 8.02919 10.8662 7.15557 11.3539 6.64461L12.7006 5.23373ZM1.77419 10.7327H6.09868C6.78842 10.7327 7.13384 11.6063 6.64613 12.1173L5.29935 13.5282C6.30781 14.5175 7.61661 15.0597 9.00503 15.0584C11.5024 15.056 13.6604 13.2624 14.2562 10.7717C14.2996 10.5904 14.4537 10.4624 14.6321 10.4624H16.4806C16.7225 10.4624 16.9062 10.6925 16.8615 10.9415C16.1635 14.8244 12.9093 17.7619 9 17.7619C6.85652 17.7619 4.90997 16.8787 3.47371 15.4408L2.32161 16.6477C1.8339 17.1586 1 16.7968 1 16.0742V11.5438C1 11.0958 1.34661 10.7327 1.77419 10.7327Z" fill="#444444" stroke="white"/>
                </svg>
              </div>
              <h1 className='m-0'>Registro nuevo: 3er. paso</h1>
              <p className='m-0 mt-1'>Envía el tercer disparo de una serie de bienvenida a nuevos registros luego de su suscripción a tu newsletter.</p>
            </div>
            
            <div className='regla_cards'>
              <div className='d-flex justify-content-between align-items-center mb-3'>
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                  <path d="M14 27.6391C21.5327 27.6391 27.6391 21.5327 27.6391 14C27.6391 6.46738 21.5327 0.360962 14 0.360962C6.46738 0.360962 0.360962 6.46738 0.360962 14C0.360962 21.5327 6.46738 27.6391 14 27.6391Z" fill="#10A36C"/>
                  <path d="M13.6073 4.05039C12.18 4.28982 10.9118 5.11428 10.1224 6.31612C9.86147 6.71081 9.79132 6.8483 9.62204 7.26029C9.16749 8.39152 9.13943 9.74441 9.54113 10.8897C9.86194 11.7988 10.468 12.655 11.19 13.2223C11.3453 13.3457 11.465 13.4584 11.458 13.4725C11.4472 13.4865 11.3416 13.5361 11.2218 13.5782C10.873 13.7086 10.186 14.0435 9.82312 14.2618C8.62175 14.9844 7.57516 16.0164 6.80682 17.2253C6.54588 17.6378 6.16194 18.4267 5.98564 18.9168C5.83038 19.3433 5.65782 20.0092 5.57318 20.5025C5.50257 20.9005 5.42868 21.8592 5.46048 21.9401C5.48152 21.9967 5.54512 21.9999 6.16148 21.9929L6.84143 21.9822L6.84844 21.8236C6.94338 20.055 7.44048 18.6736 8.45527 17.331C8.73351 16.9681 9.43825 16.2634 9.80114 15.9851C11.1508 14.9633 12.7716 14.3923 14.3359 14.389C15.2062 14.3853 15.9675 14.2165 16.7073 13.8606C17.7399 13.3602 18.5573 12.5638 19.0787 11.5458C19.6497 10.4286 19.7975 9.13928 19.4912 7.90611C19.3434 7.32109 19.1353 6.85251 18.7832 6.31706C17.9868 5.10493 16.7115 4.28047 15.2632 4.04805C14.8723 3.98352 13.995 3.98352 13.6073 4.05039ZM15.0696 5.44209C16.6128 5.66423 17.9133 6.95024 18.1879 8.52854C18.4133 9.82531 17.9414 11.1572 16.9477 12.0312C15.6191 13.1975 13.6283 13.2891 12.2296 12.2463C11.2569 11.5205 10.7215 10.516 10.6756 9.33242C10.6438 8.48318 10.8309 7.79247 11.2925 7.09849C12.1309 5.8265 13.5156 5.22043 15.0696 5.44209Z" fill="white"/>
                  <path d="M24.9031 16.7311L24.2757 16.0987C24.1458 15.9677 23.9343 15.9668 23.8033 16.0968L19.8682 20.0002L18.2037 18.3222C18.0738 18.1912 17.8623 18.1903 17.7313 18.3203L17.0988 18.9476C16.9678 19.0776 16.967 19.2891 17.0969 19.4201L19.6241 21.9678C19.7541 22.0988 19.9656 22.0996 20.0966 21.9697L24.9012 17.2036C25.0322 17.0736 25.033 16.8621 24.9031 16.7311Z" fill="white"/>
                  <defs>
                  <clipPath id="clip0_8552_582">
                  <rect width="28" height="28" fill="white"/>
                  </clipPath>
                  </defs>
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
                  <path d="M12.7006 5.23373C11.6922 4.24451 10.3835 3.70225 8.9951 3.70353C6.49645 3.70583 4.33935 5.50064 3.74377 7.99032C3.70042 8.17156 3.54629 8.29954 3.36794 8.29954H1.51945C1.27758 8.29954 1.09384 8.0695 1.13858 7.82047C1.83655 3.93752 5.09074 1 9 1C11.1435 1 13.09 1.88324 14.5263 3.32115L15.6784 2.11419C16.1661 1.60326 17 1.96513 17 2.68771V7.21813C17 7.66607 16.6534 8.02919 16.2258 8.02919H11.9013C11.2116 8.02919 10.8662 7.15557 11.3539 6.64461L12.7006 5.23373ZM1.77419 10.7327H6.09868C6.78842 10.7327 7.13384 11.6063 6.64613 12.1173L5.29935 13.5282C6.30781 14.5175 7.61661 15.0597 9.00503 15.0584C11.5024 15.056 13.6604 13.2624 14.2562 10.7717C14.2996 10.5904 14.4537 10.4624 14.6321 10.4624H16.4806C16.7225 10.4624 16.9062 10.6925 16.8615 10.9415C16.1635 14.8244 12.9093 17.7619 9 17.7619C6.85652 17.7619 4.90997 16.8787 3.47371 15.4408L2.32161 16.6477C1.8339 17.1586 1 16.7968 1 16.0742V11.5438C1 11.0958 1.34661 10.7327 1.77419 10.7327Z" fill="#444444" stroke="white"/>
                </svg>
              </div>
              <h1 className='m-0'>Cliente nuevo: 1er. paso</h1>
              <p className='m-0 mt-1'>Envía el primer disparo de una serie de bienvenida a nuevos registros luego de su suscripción a tu newsletter.</p>
            </div>
            <div className='regla_cards'>
            <div className='d-flex justify-content-between align-items-center mb-3'>
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                  <path d="M14 27.6391C21.5327 27.6391 27.6391 21.5327 27.6391 14C27.6391 6.46738 21.5327 0.360962 14 0.360962C6.46738 0.360962 0.360962 6.46738 0.360962 14C0.360962 21.5327 6.46738 27.6391 14 27.6391Z" fill="#10A36C"/>
                  <path d="M13.6073 4.05039C12.18 4.28982 10.9118 5.11428 10.1224 6.31612C9.86147 6.71081 9.79132 6.8483 9.62204 7.26029C9.16749 8.39152 9.13943 9.74441 9.54113 10.8897C9.86194 11.7988 10.468 12.655 11.19 13.2223C11.3453 13.3457 11.465 13.4584 11.458 13.4725C11.4472 13.4865 11.3416 13.5361 11.2218 13.5782C10.873 13.7086 10.186 14.0435 9.82312 14.2618C8.62175 14.9844 7.57516 16.0164 6.80682 17.2253C6.54588 17.6378 6.16194 18.4267 5.98564 18.9168C5.83038 19.3433 5.65782 20.0092 5.57318 20.5025C5.50257 20.9005 5.42868 21.8592 5.46048 21.9401C5.48152 21.9967 5.54512 21.9999 6.16148 21.9929L6.84143 21.9822L6.84844 21.8236C6.94338 20.055 7.44048 18.6736 8.45527 17.331C8.73351 16.9681 9.43825 16.2634 9.80114 15.9851C11.1508 14.9633 12.7716 14.3923 14.3359 14.389C15.2062 14.3853 15.9675 14.2165 16.7073 13.8606C17.7399 13.3602 18.5573 12.5638 19.0787 11.5458C19.6497 10.4286 19.7975 9.13928 19.4912 7.90611C19.3434 7.32109 19.1353 6.85251 18.7832 6.31706C17.9868 5.10493 16.7115 4.28047 15.2632 4.04805C14.8723 3.98352 13.995 3.98352 13.6073 4.05039ZM15.0696 5.44209C16.6128 5.66423 17.9133 6.95024 18.1879 8.52854C18.4133 9.82531 17.9414 11.1572 16.9477 12.0312C15.6191 13.1975 13.6283 13.2891 12.2296 12.2463C11.2569 11.5205 10.7215 10.516 10.6756 9.33242C10.6438 8.48318 10.8309 7.79247 11.2925 7.09849C12.1309 5.8265 13.5156 5.22043 15.0696 5.44209Z" fill="white"/>
                  <path d="M24.9031 16.7311L24.2757 16.0987C24.1458 15.9677 23.9343 15.9668 23.8033 16.0968L19.8682 20.0002L18.2037 18.3222C18.0738 18.1912 17.8623 18.1903 17.7313 18.3203L17.0988 18.9476C16.9678 19.0776 16.967 19.2891 17.0969 19.4201L19.6241 21.9678C19.7541 22.0988 19.9656 22.0996 20.0966 21.9697L24.9012 17.2036C25.0322 17.0736 25.033 16.8621 24.9031 16.7311Z" fill="white"/>
                  <defs>
                  <clipPath id="clip0_8552_582">
                  <rect width="28" height="28" fill="white"/>
                  </clipPath>
                  </defs>
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
                  <path d="M12.7006 5.23373C11.6922 4.24451 10.3835 3.70225 8.9951 3.70353C6.49645 3.70583 4.33935 5.50064 3.74377 7.99032C3.70042 8.17156 3.54629 8.29954 3.36794 8.29954H1.51945C1.27758 8.29954 1.09384 8.0695 1.13858 7.82047C1.83655 3.93752 5.09074 1 9 1C11.1435 1 13.09 1.88324 14.5263 3.32115L15.6784 2.11419C16.1661 1.60326 17 1.96513 17 2.68771V7.21813C17 7.66607 16.6534 8.02919 16.2258 8.02919H11.9013C11.2116 8.02919 10.8662 7.15557 11.3539 6.64461L12.7006 5.23373ZM1.77419 10.7327H6.09868C6.78842 10.7327 7.13384 11.6063 6.64613 12.1173L5.29935 13.5282C6.30781 14.5175 7.61661 15.0597 9.00503 15.0584C11.5024 15.056 13.6604 13.2624 14.2562 10.7717C14.2996 10.5904 14.4537 10.4624 14.6321 10.4624H16.4806C16.7225 10.4624 16.9062 10.6925 16.8615 10.9415C16.1635 14.8244 12.9093 17.7619 9 17.7619C6.85652 17.7619 4.90997 16.8787 3.47371 15.4408L2.32161 16.6477C1.8339 17.1586 1 16.7968 1 16.0742V11.5438C1 11.0958 1.34661 10.7327 1.77419 10.7327Z" fill="#444444" stroke="white"/>
                </svg>
              </div>
              <h1 className='m-0'>Cliente nuevo: 2do. paso</h1>
              <p className='m-0 mt-1'>Envía el segundo disparo de una serie de bienvenida a nuevos registros luego de su suscripción a tu newsletter.</p>
            </div>
            <div className='regla_cards'>
            <div className='d-flex justify-content-between align-items-center mb-3'>
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                  <path d="M14 27.6391C21.5327 27.6391 27.6391 21.5327 27.6391 14C27.6391 6.46738 21.5327 0.360962 14 0.360962C6.46738 0.360962 0.360962 6.46738 0.360962 14C0.360962 21.5327 6.46738 27.6391 14 27.6391Z" fill="#10A36C"/>
                  <path d="M13.6073 4.05039C12.18 4.28982 10.9118 5.11428 10.1224 6.31612C9.86147 6.71081 9.79132 6.8483 9.62204 7.26029C9.16749 8.39152 9.13943 9.74441 9.54113 10.8897C9.86194 11.7988 10.468 12.655 11.19 13.2223C11.3453 13.3457 11.465 13.4584 11.458 13.4725C11.4472 13.4865 11.3416 13.5361 11.2218 13.5782C10.873 13.7086 10.186 14.0435 9.82312 14.2618C8.62175 14.9844 7.57516 16.0164 6.80682 17.2253C6.54588 17.6378 6.16194 18.4267 5.98564 18.9168C5.83038 19.3433 5.65782 20.0092 5.57318 20.5025C5.50257 20.9005 5.42868 21.8592 5.46048 21.9401C5.48152 21.9967 5.54512 21.9999 6.16148 21.9929L6.84143 21.9822L6.84844 21.8236C6.94338 20.055 7.44048 18.6736 8.45527 17.331C8.73351 16.9681 9.43825 16.2634 9.80114 15.9851C11.1508 14.9633 12.7716 14.3923 14.3359 14.389C15.2062 14.3853 15.9675 14.2165 16.7073 13.8606C17.7399 13.3602 18.5573 12.5638 19.0787 11.5458C19.6497 10.4286 19.7975 9.13928 19.4912 7.90611C19.3434 7.32109 19.1353 6.85251 18.7832 6.31706C17.9868 5.10493 16.7115 4.28047 15.2632 4.04805C14.8723 3.98352 13.995 3.98352 13.6073 4.05039ZM15.0696 5.44209C16.6128 5.66423 17.9133 6.95024 18.1879 8.52854C18.4133 9.82531 17.9414 11.1572 16.9477 12.0312C15.6191 13.1975 13.6283 13.2891 12.2296 12.2463C11.2569 11.5205 10.7215 10.516 10.6756 9.33242C10.6438 8.48318 10.8309 7.79247 11.2925 7.09849C12.1309 5.8265 13.5156 5.22043 15.0696 5.44209Z" fill="white"/>
                  <path d="M24.9031 16.7311L24.2757 16.0987C24.1458 15.9677 23.9343 15.9668 23.8033 16.0968L19.8682 20.0002L18.2037 18.3222C18.0738 18.1912 17.8623 18.1903 17.7313 18.3203L17.0988 18.9476C16.9678 19.0776 16.967 19.2891 17.0969 19.4201L19.6241 21.9678C19.7541 22.0988 19.9656 22.0996 20.0966 21.9697L24.9012 17.2036C25.0322 17.0736 25.033 16.8621 24.9031 16.7311Z" fill="white"/>
                  <defs>
                  <clipPath id="clip0_8552_582">
                  <rect width="28" height="28" fill="white"/>
                  </clipPath>
                  </defs>
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
                  <path d="M12.7006 5.23373C11.6922 4.24451 10.3835 3.70225 8.9951 3.70353C6.49645 3.70583 4.33935 5.50064 3.74377 7.99032C3.70042 8.17156 3.54629 8.29954 3.36794 8.29954H1.51945C1.27758 8.29954 1.09384 8.0695 1.13858 7.82047C1.83655 3.93752 5.09074 1 9 1C11.1435 1 13.09 1.88324 14.5263 3.32115L15.6784 2.11419C16.1661 1.60326 17 1.96513 17 2.68771V7.21813C17 7.66607 16.6534 8.02919 16.2258 8.02919H11.9013C11.2116 8.02919 10.8662 7.15557 11.3539 6.64461L12.7006 5.23373ZM1.77419 10.7327H6.09868C6.78842 10.7327 7.13384 11.6063 6.64613 12.1173L5.29935 13.5282C6.30781 14.5175 7.61661 15.0597 9.00503 15.0584C11.5024 15.056 13.6604 13.2624 14.2562 10.7717C14.2996 10.5904 14.4537 10.4624 14.6321 10.4624H16.4806C16.7225 10.4624 16.9062 10.6925 16.8615 10.9415C16.1635 14.8244 12.9093 17.7619 9 17.7619C6.85652 17.7619 4.90997 16.8787 3.47371 15.4408L2.32161 16.6477C1.8339 17.1586 1 16.7968 1 16.0742V11.5438C1 11.0958 1.34661 10.7327 1.77419 10.7327Z" fill="#444444" stroke="white"/>
                </svg>
              </div>
              <h1 className='m-0'>Cliente nuevo: 3er. paso</h1>
              <p className='m-0 mt-1'>Envía el tercer disparo de una serie de bienvenida a nuevos registros luego de su suscripción a tu newsletter.</p>
            </div>
          </div>
      </div>
    </div>
  )
}

export default Novedades
