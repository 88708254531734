import React from 'react'
import './Campain.css'
import '../Mshop/style_mshop.css'

const DashboardCampain = () => {
  return (
    <div className='profile_bk  '>
      <div className="profile_icon">
        <h3>PERFIL</h3>
        <p>Editar/ Completar</p>
      </div>

      <div className='d-flex'>
        <div className="col col-4 col-md-4 col-sm-4 col-xs-4">
          Empresa
        </div>
        <div className="col col-4 col-md-4 col-sm-4 col-xs-4">
          Nombre
        </div>
        <div className="col col-4 col-md-4 col-sm-4 col-xs-4">
          Apellido
        </div>
      </div>
      <div className='d-flex'>
        <div className="col col-3 col-md-3 col-sm-3 col-xs-3">
          Empresa
        </div>
        <div className="col col-3 col-md-3 col-sm-3 col-xs-3">
          Nombre
        </div>
        <div className="col col-3 col-md-3 col-sm-3 col-xs-3">
          Apellido
        </div>
        <div className="col col-3 col-md-3 col-sm-3 col-xs-3">
          Apellido
        </div>
      </div>
      
    </div>
  )
}

export default DashboardCampain
