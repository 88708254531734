/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { useSelector } from 'react-redux'
import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import HomeMenu from '../components/Home/HomeMenu'
import MenuRuleConfig from '../components/Home/RuleConfig/MenuRuleConfig'
import SubMenuMenuSystem from '../components/Home/System/SubMenuSystem'
import SubMenuBrandPriority from '../components/Home/BrandPriority/SubMenuBrandPriority'
import {
  Dashboard,
  Login,
  Mshop,
  MshopTyPage,
  MshopRegister,
  MshopInvalidGrant,
  MshopInactive
} from '../pages'
import { RootState } from '../state/store'
// import options from "../utils/mainMenuOptions";
import {
  cdpSubMenuOptions,
  subOptions,
  subSearchOptions,
  subAutomationOptions,
  systemSubMenuOptions,
  // systemSubMenuOptionsForUsers,
  cdpSubMenuOptionsForUsers,
  subCampainOptions,
  SubMenuSmartImagesOptions,
  SubMenuMshopOptions,
  subCampainOptionsMusimundo
  // CampainSubMenu
} from '../utils/secondMenuOptions'
import {
  ProtectedAuthRoute,
  ProtectedDashRoute,
  ProtectedRouteMercadoShop
} from './ProtectedRoutes'
import SubMenuCDP from '../components/Home/CDP/SubMenuCDP'
import HomeSiteContainer from '../components/Home/HomeSite/HomeSiteContainer'
import ArticlesTableContainer from '../components/Home/System/ArticlesTableContainer'
// import Campain from '../components/Campain/Campain'
import SubMenuCampain from '../components/Campain/SubMenuCampain'
import SubMenuSmartImages from '../components/Home/Images/SubMenuSmartImages'
import DashboardMshop from '../pages/DashboardMshop'
import MshopContainerCarrousel from '../components/Mshop/Carrousel/MshopContainerCarrousel'
import MshopContainerFacturacion from '../components/Mshop/Facturacion-Plan/MshopContainerFacturacion'
import ProfileMshopContainer from '../components/Home/System/ProfileMshopContainer'
import SubMenusMshop from '../components/Mshop/SubMenuMshop'
import PasswordRecovery from '../components/Login/PasswordRecovery'
import { MshopContextProvider } from '../contexts/MshopContext'
import { ClientContextProvider } from '../contexts/ClientContext'
import { Plans } from '../components/Mshop/Facturacion-Plan/Plans'
import MshopInstructivo from '../pages/MshopInstructivo'
import UnderConstruction from '../pages/UnderConstruction'

const AppRoutes = () => {
  const userData = useSelector<RootState, StateResponse<UserInfo>>(
    (state: RootState) => state.userLogin
  )
  const userAuth = useSelector<RootState, StateResponse<GoogleAuth>>(
    (state: RootState) => state.userAuth
  )

  const user: any = useSelector<RootState, any>((state) => state.userAuth)

  const client: any = (localStorage.getItem('clientSelected'))

  const clientSelected = JSON.parse(client)

  const userValidate = (user: any) => {
    if (
      user == 'vpaul' ||
      user == 'darilo' ||
      user == 'darede' ||
      user == 'lseidan' ||
      user == 'psanmillan' ||
      user == 'fcansino' ||
      user == 'rcibrian'
    ) {
      return true
    } else {
      return false
    }
  }

  const isAdmin: any = (userType: number): boolean => userType === 1

  return (
    <Routes>
      <Route path="mshop" element={<Mshop />} />
      <Route path="mshopthankspage" element={<MshopTyPage />} />
      <Route path="mshop-tutorial" element={<MshopInstructivo />} />
      <Route path="mshopregisterpage" element={<MshopRegister />} />
      <Route path="login" element={<Login />} />
      <Route path="PasswordRecovery" element={<PasswordRecovery />} />
      <Route element={<ProtectedAuthRoute />}>
        <Route path="authtoken" element={<Login />} />
      </Route>
      <Route
        element={
          <ProtectedDashRoute
            role={user.data?.userType}
            userType={user.data?.userType}
          />
        }
      >
        <Route
          path="dashboard"
          element={
            <>
              <ClientContextProvider>
                <Dashboard />
              </ClientContextProvider>
            </>
          }
        >
          <Route
            path="homebdw"
            element={
              <>
                {user.data?.isActiveAppMshop === false &&
                user.data?.userType !== 1 ? null : (
                  <>
                    <HomeSiteContainer />
                    <Outlet />
                  </>
                )}
              </>
            }
          />
          {isAdmin(user.data?.userType) && (
            <Route
              path="home"
              element={
                <>
                  <HomeMenu />
                  <Outlet />
                </>
              }
            >
              {subOptions.map((nav) => (
                <Route key={nav.id} path={nav.link} element={nav.component} />
              ))}
            </Route>
          )}
          {isAdmin(user.data?.userType) && (
            <Route
              path="search"
              element={
                <>
                  <SubMenuBrandPriority />
                  <Outlet />
                </>
              }
            >
              {subSearchOptions.map((nav) => (
                <Route key={nav.id} path={nav.link} element={nav.component} />
              ))}
            </Route>
          )}
          {isAdmin(user.data?.userType) && (
            <Route
              path="automation"
              element={
                <>
                  <MenuRuleConfig />
                  <Outlet />
                </>
              }
            >
              {subAutomationOptions.map((nav) => (
                <Route key={nav.id} path={nav.link} element={nav.component} />
              ))}
            </Route>
          )}
          {isAdmin(user.data?.userType) && (
            <Route
              path="cdp"
              element={
                <>
                  <SubMenuCDP />
                  <Outlet />
                </>
              }
            >
              {user.data?.userType == 0
                ? cdpSubMenuOptionsForUsers.map((nav: any) => (
                    <Route
                      key={nav.id}
                      path={nav.link}
                      element={nav.component}
                    />
                  ))
                : cdpSubMenuOptions.map((nav: any) => (
                    <Route
                      key={nav.id}
                      path={nav.link}
                      element={nav.component}
                    />
                  ))}
            </Route>
          )}
          {isAdmin(user.data?.userType) && (
            <Route
              path="campain"
              element={
                <>
                  <SubMenuCampain />
                  <Outlet />
                </>
              }
            >
              {clientSelected && clientSelected.clientName !== 'musimundo_hybris'
                ? subCampainOptions.map((nav) => (
                    <Route
                      key={nav.id}
                      path={nav.link}
                      element={nav.component}
                    />
                  ))
                : subCampainOptionsMusimundo.map((nav) => (
                    <Route
                      key={nav.id}
                      path={nav.link}
                      element={nav.component}
                    />
                  ))}
            </Route>
          )}
          <Route
            path="system"
            element={
              <>
                <SubMenuMenuSystem />
                <Outlet />
              </>
            }
          >
            {isAdmin(user.data?.userType)
              ? systemSubMenuOptions.map((nav) => (
                  <Route key={nav.id} path={nav.link} element={nav.component} />
                ))
              : systemSubMenuOptions
                  // eslint-disable-next-line @typescript-eslint/no-unnecessary-boolean-literal-compare
                  .filter((nav) => nav.protected === false)
                  .map((nav) => (
                    <Route
                      key={nav.id}
                      path={nav.link}
                      element={nav.component}
                    />
                  ))}
          </Route>
          <Route
            path="smart-images"
            element={
              <>
                <SubMenuSmartImages />
                <Outlet />
              </>
            }
          >
            {SubMenuSmartImagesOptions.map((nav) => (
              <Route key={nav.id} path={nav.path} element={nav.component} />
            ))}
          </Route>
          {isAdmin(user.data?.userType) && (
            <Route
              path="mshop-menu"
              element={
                <>
                  <MshopContextProvider>
                    <SubMenusMshop />
                    <Outlet />
                  </MshopContextProvider>
                </>
              }
            >
              {SubMenuMshopOptions.map((nav) => (
                <Route key={nav.id} path={nav.link} element={nav.component} />
              ))}
            </Route>
          )}
          {userValidate(userAuth.data?.username) ? (
            <Route
              path="articles-table-panel"
              element={
                <>
                  <ArticlesTableContainer />
                  <Outlet />
                </>
              }
            ></Route>
          ) : null}
        </Route>
      </Route>
      <Route element={<ProtectedRouteMercadoShop />}>
        <Route
          path="dashboardmshop"
          element={
            <>
              <ClientContextProvider>
                <MshopContextProvider>
                  <DashboardMshop />
                </MshopContextProvider>
              </ClientContextProvider>
            </>
          }
        >
          <Route
            path="mshop-disabled"
            element={
              user.data?.disabledAppBy === 'Mshop' ? (
                <>
                  <div className="main-menu-dashboard">
                    <MshopInvalidGrant />
                  </div>
                </>
              ) : user.data?.disabledAppBy === 'PanelBdw' ? (
                <>
                  <div className="main-menu-dashboard">
                    <MshopInactive />
                  </div>
                </>
              ) : null
            }
          ></Route>
          <Route
            path="homebdw"
            element={
              <>
                {user.data?.isActiveAppMshop === false &&
                user.data?.userType !== 1 ? null : (
                  <UnderConstruction />
                )}
              </>
            }
          ></Route>
          {user.data?.isActiveAppMshop && (
            <Route
              path="carruselesmshop"
              element={
                <>
                  <MshopContainerCarrousel />
                  <Outlet />
                </>
              }
            ></Route>
          )}
          <Route
            path="facturacion"
            element={
              <>
                <MshopContainerFacturacion />
                <Outlet />
              </>
            }
          ></Route>
          <Route path="help" element={<UnderConstruction />} />
          <Route
            path="profile"
            element={
              <>
                <ProfileMshopContainer />
                <Outlet />
              </>
            }
          ></Route>
          <Route path="mshop-subscriptions" element={<Plans />} />
        </Route>
      </Route>
      {userData.loading == true && userAuth.loading == true ? (
        <Route path="*" element={<Navigate to="dashboard" />} />
      ) : (
        <Route path="*" element={<Navigate to="login" />} />
      )}
    </Routes>
  )
}

export default AppRoutes
