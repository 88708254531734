import React from 'react'
import './Campain.css'
import { Bar, Doughnut } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'
import { faker } from '@faker-js/faker'
const Marketing = () => {
  ChartJS.register(
    ArcElement,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  )

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom' as const
      },
      title: {
        display: true,
        text: 'Bloque de Métricas GA4'
      }
    }
  }

  const optionsHorizontal = {
    indexAxis: 'y' as const,
    scales: {
      x: {
        beginAtZero: true
      }
    },
    elements: {
      bar: {
        borderWidth: 2
      }
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'right' as const
      },
      title: {
        display: true,
        text: 'Gráfico de Barras Horizontal'
      }
    }
  }

  const optionsDoughnut = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom' as const
      }
    }
  }

  const labels = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July'
  ]

  const labelsCategorias = [
    'Laptos',
    'Tablets',
    'Scooter Eléctronicos',
    'Apple Smartwatches'
  ]

  const dataBar = {
    labels,
    datasets: [
      {
        label: 'Datos 1',
        data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
        backgroundColor: 'rgba(43, 174, 126, 1)'
      },
      {
        label: 'Datos 2',
        data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
        backgroundColor: 'rgba(255, 178, 69, 1)'
      }
    ]
  }

  const dataBarCategorias = {
    labels: labelsCategorias,
    datasets: [
      {
        label: 'Datos 1',
        data: labelsCategorias.map(() =>
          faker.datatype.number({ min: 0, max: 1000 })
        ),
        borderColor: 'rgba(43, 174, 126, 1)',
        backgroundColor: 'rgba(43, 174, 126, 1)'
      }
    ]
  }

  const dataDoughnutClients = {
    labels: ['Nuevos', 'Recurrentes'],
    datasets: [
      {
        label: '# of Votes',
        data: [12, 19],
        backgroundColor: [
          'rgba(43, 174, 126, 1)',
          'rgba(255, 178, 69, 1)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)'
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)'
        ],
        borderWidth: 1
      }
    ]
  }

  const dataDoughnutEngagement = {
    labels: ['Alto', 'Medio', 'Bajo'],
    datasets: [
      {
        label: '# of Votes',
        data: [12, 19, 50],
        backgroundColor: [
          'rgba(43, 174, 126, 1)',
          'rgba(255, 178, 69, 1)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)'
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)'
        ],
        borderWidth: 1
      }
    ]
  }

  return (
    <div>
      <div className=" justify-content-center mt-3 row" style={{padding: '30px'}}>
        <div className="col-md-6">
          <div className="regla_frame justify-content-center mt-3 mx-3">
            <div className="justify-content-center">
              <span>Graficos de Ingreso</span>
            </div>
            <div className="justify-content-center">
              <Bar options={options} data={dataBar} />
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="regla_frame d-flex justify-content-center mt-3 mx-3">
            <div>
              <Doughnut data={dataDoughnutClients} options={optionsDoughnut} />
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center mt-3  row" style={{padding: '30px'}}>
        <div className="col-md-12">
          <div className="regla_frame justify-content-center mx-3">
            <h1>Ventas</h1>
            <div>
              <span
                style={{
                  fontSize: '16px',
                  fontFamily: 'Poppins',
                  color: '#666666 !important'
                }}
              >
                Resumen de ventas
              </span>
            </div>
            <div className="row" style={{ fontFamily: 'Poppins' }}>
              <div className="col-md-2 sales_resume mr-5">
                <img src={require('../../img/ingresos.png')} />
                <div>
                  <h2 className="mt-3">$4.03M</h2>
                </div>
                <div>
                  <span>Ingresos</span>
                </div>
                <div>
                  <span className="sales_subtitle">Revenue</span>
                </div>
              </div>
              <div className="col-md-2 sales_resume mr-5">
                <img src={require('../../img/transecciones.png')} />
                <div>
                  <h2 className="mt-3">9.52K</h2>
                </div>
                <div>
                  <span>Transacciones</span>
                </div>
                <div>
                  <span className="sales_subtitle">Transacciones</span>
                </div>
              </div>
              <div className="col-md-2 sales_resume mr-5">
                <img src={require('../../img/tickets.png')} />
                <div>
                  <h2 className="mt-3">413.92</h2>
                </div>
                <div>
                  <span>Tickets</span>
                </div>
                <div>
                  <span className="sales_subtitle">Ticket Promedio</span>
                </div>
              </div>
              <div className="col-md-2 sales_resume mr-5">
                <img src={require('../../img/pdp.png')} />
                <div>
                  <h2 className="mt-3">198.38</h2>
                </div>
                <div>
                  <span>PpP</span>
                </div>
                <div>
                  <span className="sales_subtitle">PpP</span>
                </div>
              </div>
              <div className="col-md-2 sales_resume mr-5">
                <img src={require('../../img/upt.png')} />
                <div>
                  <h2 className="mt-3">2.1</h2>
                </div>
                <div>
                  <span>UPT</span>
                </div>
                <div>
                  <span className="sales_subtitle">
                    Unidad por transacciones
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" justify-content-center mt-3 row" style={{padding: '30px'}}>
        <div className="col-md-6">
          <div className="regla_frame justify-content-center mt-3 mx-3">
            <h1>Categorías - Ranking</h1>
            <div className="justify-content-center">
              <span className="mr-5">Productos</span> <span>Alcance</span>
            </div>
            <div className="justify-content-center">
              <Bar options={optionsHorizontal} data={dataBarCategorias} />
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="regla_frame justify-content-center mt-3 mx-3 ">
            <div className="row justify-content-between">
              <div className="col-md-4 text-center">
                <h1>Engagement</h1>
              </div>
              <div className=" col-md-4"><button type="button" className="btn btn-light">Exportar</button></div>
            </div>
            <div>
              <div style={{width: '280px',}}><Doughnut data={dataDoughnutEngagement} options={optionsDoughnut} /></div>
            </div>
            <div className="row mt-5 justify-content-evenly" style={{ fontFamily: 'Poppins' }}>
              <div className="col-md-3 sales_resume mr-2">
                <div>
                  <span>Emails Abiertos</span>
                </div>
                <div className='text-center'>
                  <span >141.15K</span>
                </div>
                <div className='text-center'>
                  <span className="sales_subtitle ">14% Enviados</span>
                </div>
              </div>
              <div className="col-md-3 sales_resume mr-2">
                <div>
                  <span>Emails Clickeados</span>
                </div>
                <div className='text-center'>
                  <span >3.30K</span>
                </div>
                <div className='text-center'>
                  <span className="sales_subtitle ">2.3% Abiertos</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Marketing
