/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Service from '../../../services'
import { useSelector } from 'react-redux'
import { RootState } from '../../../state/store'
import { FormattedMessage, useIntl } from 'react-intl'
import { useAtom } from 'jotai'
import styled from 'styled-components'
import { IoMdImages } from 'react-icons/io'

import CarrouselTabContent from './CarrouselTabContent'
import {
  homeAtom,
  pdpAtom,
  plpAtom,
  searchAtom,
  searchNoResultAtom
} from '../../../atoms/carrousel'
import { ShowAlert } from '../../Globals'
import { useMshopContext } from '../../../contexts/MshopContext'
import { useClientContext } from '../../../contexts/ClientContext'

const MshopHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: #444;
  margin-bottom: 43px;
`
const MshopHeaderContainerTitle = styled.div`
  margin-bottom: 10px;
  display: flex;
  align-items: center;
`

const ContainerIcon = styled.div`
  background-color: #00868b;
  padding: 0.54rem;
  border-radius: 100%;
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
`
const Button = styled.button`
  background-color: #00868b !important;
  &:hover {
    color: white !important;
  }
`

const MshopContainerCarrousel = () => {
  const user = useSelector<RootState, any>((state) => state.userAuth)
  const { selectedClient } = useClientContext()
  const navigate = useNavigate()
  const intl = useIntl()
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [homeSection, setHomeSection] = useAtom(homeAtom)
  const [plpSection, setPlpSection] = useAtom(plpAtom)
  const [pdpSection, setPdpSection] = useAtom(pdpAtom)
  const [searchSection, setSearchSection] = useAtom(searchAtom)
  const [searchNoResultSection, setSearchNoResultSection] =
    useAtom(searchNoResultAtom)

  let carrouselsData: any = []
  const { planStatus } = useMshopContext()

  const MyAlert = (text: string, iconStr: number) => {
    ShowAlert({
      title: text,
      icon: iconStr == 1 ? 'success' : iconStr == 2 ? 'error' : 'warning',
      showConfirmButton: true,
      timer: 3000,
      closeButtonHtml: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      cancelButtonText: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      width: 500,
      timerProgressBar: true
    })
  }

  useEffect(() => {
    Service.get<any>(`/Account/ProfileStatus`, {
      headers: { token: user.data.token }
    })
      .then((response) => {
        if (
          response.data.Status !== 'OK' &&
          response.data.Response.Platform === 'MercadoShop' &&
          response.data.Response.Message !== 'Los datos están completos'
        ) {
          navigate('/dashboardmshop/profile')
        }
      })
      .catch((error: any) => {
        console.log(error)
      })
    Service.get<any>(
      `/Mshop/carrouselsList?idClient=${selectedClient?.idClient}`
    ).then((response: any) => {
      if (response.data.length > 0) {
        const homeData = response.data.find(
          (item: any) => item.Section === 'Home'
        )
        const plpData = response.data.find(
          (item: any) => item.Section === 'Plp'
        )
        const pdpData = response.data.find(
          (item: any) => item.Section === 'Pdp'
        )
        const searchData = response.data.find(
          (item: any) => item.Section === 'Search'
        )
        const searchNoResultData = response.data.find(
          (item: any) => item.Section === 'SearchNoResult'
        )
        if (homeData) {
          setHomeSection(homeData.Carrusels)
        }
        if (plpData) {
          setPlpSection(plpData.Carrusels)
        }
        if (pdpData) {
          setPdpSection(pdpData.Carrusels)
        }
        if (searchData) {
          setSearchSection(searchData.Carrusels)
        }
        if (searchNoResultData) {
          setSearchNoResultSection(searchNoResultData.Carrusels)
        }
      }
    })
  }, [])

  useEffect(() => {
    carrouselsData = [
      {
        Section: 'Home',
        carrusels: homeSection
      },
      {
        Section: 'Plp',
        carrusels: plpSection
      },
      {
        Section: 'Pdp',
        carrusels: pdpSection
      },
      {
        Section: 'Search',
        carrusels: searchSection
      },
      {
        Section: 'SearchNoResult',
        carrusels: searchNoResultSection
      }
    ]
  }, [
    homeSection,
    plpSection,
    pdpSection,
    searchSection,
    searchNoResultSection
  ])

  const saveCarrousels = useCallback(async () => {
    try {
      if (!selectedClient) {
        return
      }
      setSubmitting(true)
      const { data } = await Service.post<any, any>(
        `/Mshop/carrouselsUpdate?idClient=${selectedClient?.idClient}`,
        planStatus?.PlanName === 'Basico'
          ? [carrouselsData.find((crls: any) => crls.Section == 'Home')]
          : carrouselsData,
        {
          method: 'POST',
          headers: {
            token: user.data.token,
            username: user.data.username,
            // tokenGoogle,
            'Content-Type': 'application/json',
            Accept: '*/*',
            credentials: 'include'
          }
        }
      )
      setSubmitting(false)
      if (data.Status !== 'Ok') {
        MyAlert('Ocurrió un Error al actualizar los datos.', 2)
      }
      MyAlert(
        intl.formatMessage(
          { id: 'app.api.update.ok' },
          { defaultMessage: 'Se actualizaron con éxito los datos.' }
        ),
        1
      )
    } catch (error) {
      setSubmitting(false)
      MyAlert('Ocurrió un Error al actualizar los datos.', 2)
    }
  }, [selectedClient, carrouselsData])

  return (
    <>
      <div className="container mg-t-30">
        <MshopHeaderContainer>
          <MshopHeaderContainerTitle>
            <ContainerIcon>
              <IoMdImages size={30} color="white" />
            </ContainerIcon>
            <h4 style={{ margin: 0, marginLeft: 12 }} className="alert-heading">
              Administración de Carruseles
            </h4>
          </MshopHeaderContainerTitle>
          <p style={{ lineHeight: 2.3 }}>
            Desde aquí podrás administrar los carruseles personalizados en cada
            sección de tu tienda. Define la regla de personalización que mejor
            se adapte a tu estrategia, otórgale un título, selecciona la
            ubicación en la plantilla y actívala cuando consideres.
          </p>
        </MshopHeaderContainer>
        <div className="mt-3">
          <CarrouselTabContent />
        </div>
        <div className="row shelves button botonera">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 shelves__buttons-container">
            <div className="shelves__preview-button">
              <div className="shelves__preview-button mb-0">
                <Button
                  className="btn"
                  id="saveBrandPriority"
                  onClick={() => {
                    saveCarrousels()
                  }}
                  disabled={submitting}
                >
                  <FormattedMessage
                    id="app.mshop.carrousel.btn-update"
                    defaultMessage="Actualizar Vitrinas"
                  />
                </Button>
                <div className="shelves__preview-button"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MshopContainerCarrousel
