import React, { useState } from 'react'
import './Clientes.css'

const ClientesActividad = () => {
  const [activeButton, setActiveButton] = useState(null)

  const handleButtonClick = (buttonIndex: any) => {
    setActiveButton(buttonIndex)
  }
  return (
    <div className="perfil-contenedor">
      <div className="d-flex justify-content-end align-items-center mt-3 mr-5">
        <div className="col-md-12 container mb-3">
          <div>
            <div style={{ padding: '30px 0px 0px 0px' }}>
              <h1 className="text_poppins">Perfil</h1>
            </div>
            <div className="container">
              <div
                className="d-flex justify-content-between align-items-center my-3"
                style={{ padding: '30px' }}
              >
                <div>
                  <p
                    className="text_poppins"
                    style={{ color: '#6529A1', fontSize: '15px' }}
                  >
                    <img src={require('../../img/perfil.png')} />{' '}
                    nombre-usuario@gmail.com
                  </p>
                  <p>Creado el dd/ mm/ aaaa</p>
                </div>
              </div>
              <div
                className="d-flex regla_frame justify-content-between align-items-center my-3"
                style={{ padding: '30px' }}
              >
                <div className="button-group">
                  <button
                    className={`btn clp_btn-primary d-flex me-2 align-items-center ${
                      activeButton === 0 ? 'clp-active' : ''
                    }`}
                    onClick={() => handleButtonClick(0)}
                  >
                    Perfil
                  </button>
                  <button
                    className={`btn clp_btn-primary d-flex me-2 align-items-center ${
                      activeButton === 1 ? 'clp-active' : ''
                    }`}
                    onClick={() => handleButtonClick(1)}
                  >
                    Actividad
                  </button>
                </div>
              </div>
              <div className='d-flex justify-content-between'>

              <div
                className="regla_frame mb-3"
                style={{
                  width:"48%"
                }}
              >
                <div style={{ gridColumn: 'span 6' }}>
                  <h3 className="cfa-text_poppins">Emails</h3>
                  <div className="cfa-email-list">
                    <div className="cfa-email-item">
                      <div className="cfa-email-icon">
                        <img
                          src={require('../../img/clientes-mail.png')}
                          alt="Email Abierto"
                        />
                      </div>
                      <div className="cfa-email-details">
                        <p className="cfa-email-status">Email Abierto</p>
                        <p className="cfa-email-detail">
                          <span className='cfa-text_bold'>Nombre:</span> Audiencia Deportes
                        </p>
                        <p className="cfa-email-type">
                          <span className='cfa-text_bold'>Tipo de Email:</span> Transaccional
                        </p>
                        <a href="#" className="cfa-email-link">
                          Ver
                          <img
                          src={require('../../img/clientes-ver.png')}
                          alt="Ver"
                        />
                        </a>
                      </div>
                      <div className="cfa-email-time">
                        <p>25 de mayo de 2024</p>
                        <p>11:30:25</p>
                        
                      </div>
                    </div>
                  </div>
                </div>

               
              </div>
              <div  className="regla_frame mb-3" style={{width:"50%"}}>
              <div style={{ gridColumn: 'span 6'}}>
                  <h3 className="cfa-text_poppins">Segmentos</h3>
                  <div className="cfa-segment-list">
                    <div className="cfa-segment-item">
                      <div className="cfa-segment-icon">
                        <img src={require('../../img/clientes-segmento.png')} alt="Segmento" />
                      </div>
                      <div className="cfa-segment-details">
                        <p className="cfa-segment-action font-weight-bold">
                          Ha entrado en segmento
                        </p>
                        <p className="cfa-segment-system">Sistema</p>
                        <p className="cfa-segment-name">
                          <span className='font-weight-bold'>Nombre:</span> Compradores + 3 meses
                        </p>
                      </div>
                      <div className="cfa-segment-time">
                        <p>25 de mayo de 2024</p>
                        <p>11:30:25</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ClientesActividad
