
import React from 'react'
import './Campain.css'

const Campain
 = () => {
  return (
    <div>
        
        <div className='d-flex justify-content-end align-items-center mt-3 mr-5'>
          <button className='btn_gral mr-2'>
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17" viewBox="0 0 18 17" fill="none">
              <path d="M16.4768 0.819702H2.23841C1.45233 0.819702 0.814568 1.46016 0.814568 2.24956V11.7819C0.814568 12.5713 1.45233 13.2118 2.23841 13.2118H7.93377L7.45916 14.6417H5.3234C4.92887 14.6417 4.61148 14.9604 4.61148 15.3566C4.61148 15.7528 4.92887 16.0715 5.3234 16.0715H13.3918C13.7864 16.0715 14.1038 15.7528 14.1038 15.3566C14.1038 14.9604 13.7864 14.6417 13.3918 14.6417H11.2561L10.7815 13.2118H16.4768C17.2629 13.2118 17.9007 12.5713 17.9007 11.7819V2.24956C17.9007 1.46016 17.2629 0.819702 16.4768 0.819702ZM16.0022 11.3053H2.71302V2.72618H16.0022V11.3053Z" fill="#444444"/>
            </svg>
            <span className='m-2'>Monitor de envíos</span>
          </button>
          <button className='btn_gral btn_green'>
            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
            <path d="M8.49007 0.819702C4.24266 0.819702 0.801331 4.27558 0.801331 8.54093C0.801331 12.8063 4.24266 16.2622 8.49007 16.2622C12.7375 16.2622 16.1788 12.8063 16.1788 8.54093C16.1788 4.27558 12.7375 0.819702 8.49007 0.819702ZM12.9545 9.41268C12.9545 9.61817 12.7871 9.78629 12.5825 9.78629H9.73019V12.6506C9.73019 12.8561 9.56278 13.0242 9.35816 13.0242H7.62199C7.41737 13.0242 7.24995 12.8561 7.24995 12.6506V9.78629H4.39768C4.19306 9.78629 4.02564 9.61817 4.02564 9.41268V7.66918C4.02564 7.4637 4.19306 7.29557 4.39768 7.29557H7.24995V4.43125C7.24995 4.22576 7.41737 4.05764 7.62199 4.05764H9.35816C9.56278 4.05764 9.73019 4.22576 9.73019 4.43125V7.29557H12.5825C12.7871 7.29557 12.9545 7.4637 12.9545 7.66918V9.41268Z" fill="#2BAE7E"/>
            </svg>
            <span className='m-2'>Nueva Campaña</span>
          </button>
        </div>

      
        <div className='d-flex justify-content-around'>
            <div className='three_cards bg_styles'>
              Mensajes totales enviados
              <div className='d-flex justify-content-center align-items-center mt-3'>
                <div className='green_circle'></div>
                <p>
                  Number
                  <span className='mt-2'>$0,07 Revenue por mensaje.</span>
                </p>
              </div>
            </div>

            <div className='three_cards bg_styles'></div>

            <div className='three_cards bg_styles'></div>
        </div>
        <p className='claim_sm'>*Valores calculados para los filtros aplicados.</p>

        
        <div className='search_card bg_styles'>
            <button className='btn_gral'>
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="12" viewBox="0 0 18 12" fill="none">
                <path d="M15.6362 4.89903H14.2175V3.51078C14.2175 2.74436 13.5808 2.12254 12.7959 2.12254H8.057L6.16145 0.271545H1.42258C0.637702 0.271545 0.000915527 0.893363 0.000915527 1.65979V9.98926C0.000915527 10.7557 0.637702 11.3775 1.42258 11.3775H13.2698C13.7585 11.3775 14.2146 11.1317 14.4752 10.7239L16.8417 7.02189C17.434 6.09928 16.7528 4.89903 15.6362 4.89903ZM1.42258 1.83332C1.42258 1.73788 1.50255 1.65979 1.60029 1.65979H5.57205L7.4676 3.51078H12.6182C12.7159 3.51078 12.7959 3.58887 12.7959 3.68431V4.89903H4.50285C4.00526 4.89903 3.54322 5.15354 3.28555 5.57001L1.42258 8.58366V1.83332ZM13.2698 9.98926H2.13341L4.41992 6.28727H15.6392L13.2698 9.98926Z" fill="#444444"/>
              </svg>
              <span className='m-2'>Open File</span>
            </button>
            <div className='searchfor'>
              <input className='search_txt' placeholder='Buscar por nombre o asunto'></input>
            </div>

            <div className="col col-2 col-md-2 col-sm-2 col-xs-2">
              <select className="form-select dropdw">
                  <option selected>Todos los canales</option>
                  <option>Canal 1</option>
                  <option>Canal 2</option>
              </select>
            </div>
            <div className="col col-3 col-md-3 col-sm-3 col-xs-3">
              <select className="form-select dropdw">
                  <option selected>Actividad en este mes</option>
                  <option>Actividad 1</option>
                  <option>Actividad 2</option>
              </select>
            </div>

            <button className='btn_gral'>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path d="M10.3517 3.35571L7.15907 0.615167C7.01002 0.484292 6.82184 0.417335 6.63267 0.417236C6.50324 0.417169 6.37334 0.448403 6.2552 0.511881C6.20255 0.540095 6.15252 0.574554 6.10627 0.615167L2.91361 3.35571C2.57828 3.64355 2.53941 4.14917 2.82681 4.48502C3.11421 4.82088 3.61903 4.8598 3.95436 4.57196L5.83296 2.95939V9.16216C5.83296 9.60449 6.19098 9.96307 6.63262 9.96307C7.07426 9.96307 7.43228 9.60449 7.43228 9.16216L7.43228 2.9593L9.31098 4.57196C9.64631 4.8598 10.1511 4.82088 10.4385 4.48502C10.7259 4.14917 10.6871 3.64355 10.3517 3.35571ZM1.83465 8.42634C1.83465 7.98401 2.19267 7.62543 2.63431 7.62543H3.8338C4.27544 7.62543 4.63346 7.26685 4.63346 6.82452C4.63346 6.38219 4.27544 6.02361 3.8338 6.02361H2.63431C1.30939 6.02361 0.235336 7.09935 0.235336 8.42634V10.8291C0.235336 12.1561 1.30939 13.2318 2.63431 13.2318H10.6309C11.9558 13.2318 13.0299 12.1561 13.0299 10.8291V8.42634C13.0299 7.09935 11.9558 6.02361 10.6309 6.02361H9.43141C8.98977 6.02361 8.63175 6.38219 8.63175 6.82452C8.63175 7.26685 8.98977 7.62543 9.43141 7.62543H10.6309C11.0725 7.62543 11.4306 7.98401 11.4306 8.42634V10.8291C11.4306 11.2714 11.0725 11.63 10.6309 11.63H2.63431C2.19267 11.63 1.83465 11.2714 1.83465 10.8291V8.42634Z" fill="#444444"/>
            </svg>
              <span className='m-2'>Expotar CSV</span>
            </button>
        </div>

        
        <div className='list_card bg_styles'>

          <div className='d-flex mb-4 justify-content-between align-items-center'>
            <div className='d-flex'>
              <h2 className='selected'>Única vez</h2>
              <h2>Automatizadas</h2>
              <h2>Todas</h2>
            </div>
            <div className='d-flex'>
              <p className='lh_txt'>Ordenado&nbsp;por:</p>
              <select className="form-select dropdw">
                <option selected>Última Actualización</option>
                <option>Actualización 1</option>
                <option>Actualización 2</option>
              </select>
            </div>
          </div>

          <div className='d-flex justify-content-between'>
            <div>
              <div className='d-flex align-items-center'>
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="17" viewBox="0 0 22 17" fill="none">
                  <path d="M19.8586 0.297974H2.50557C1.39973 0.297974 0.503296 1.19441 0.503296 2.30025V14.3139C0.503296 15.4197 1.39973 16.3162 2.50557 16.3162H19.8586C20.9645 16.3162 21.8609 15.4197 21.8609 14.3139V2.30025C21.8609 1.19441 20.9645 0.297974 19.8586 0.297974ZM19.8586 2.30025V4.00239C18.9233 4.76405 17.4322 5.9484 14.2445 8.44453C13.542 8.99711 12.1504 10.3247 11.1821 10.3092C10.214 10.3248 8.82196 8.99691 8.11975 8.44453C4.9325 5.94877 3.44101 4.76418 2.50557 4.00239V2.30025H19.8586ZM2.50557 14.3139V6.57169C3.46141 7.33301 4.81691 8.40135 6.88297 10.0192C7.79471 10.7369 9.3914 12.3212 11.1821 12.3116C12.964 12.3212 14.5405 10.7599 15.4809 10.0195C17.5469 8.40173 18.9028 7.3331 19.8586 6.57173V14.3139H2.50557Z" fill="#666666"/>
                </svg>
                <h3>Nombre de la Campaña</h3>
              </div>
              <div className='d-flex align-items-center pl'>
                <p className='txt-send-camp'>Enviada:</p>
                <p>(Fecha y hora)</p>
              </div>
              <p className='pl'>Válido desde (fecha) hasta (fecha)</p>
            </div>

            <div>
            <button className='btn_gral'>
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14" fill="none">
              <path d="M6.28662 13.0408L0.733639 7.48777C0.400026 7.15416 0.400026 6.61324 0.733639 6.27959L1.94178 5.07142C2.27539 4.73777 2.81634 4.73777 3.14995 5.07142L6.89071 8.81214L14.903 0.799892C15.2366 0.466279 15.7776 0.466279 16.1112 0.799892L17.3193 2.00807C17.6529 2.34168 17.6529 2.8826 17.3193 3.21624L7.4948 13.0408C7.16115 13.3744 6.62024 13.3744 6.28662 13.0408Z" fill="#10A36C"/>
            </svg>
              <span className='m-2'>Enviada</span>
            </button>
            </div>

              <div>
                <p>(Número)</p>
                <p>Enviados</p>
              </div>
              <div>
                <p>($ Número)</p>
                <p>Revenue</p>
              </div>
              <div>
                <p>(Número)</p>
                <p>Tickets</p>
              </div>

            <div>
            <button className='btn_gral'>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="15" viewBox="0 0 20 15" fill="none">
              <path d="M12.6536 10.0927H14.0632C14.2982 10.0927 14.5331 9.85773 14.5331 9.6228V4.68919C14.5331 4.45425 14.2982 4.21932 14.0632 4.21932H12.6536C12.4187 4.21932 12.1838 4.45425 12.1838 4.68919V9.6228C12.1838 9.85773 12.4187 10.0927 12.6536 10.0927ZM16.1776 10.0927H17.5872C17.8222 10.0927 18.0571 9.85773 18.0571 9.6228V1.16518C18.0571 0.930246 17.8222 0.695312 17.5872 0.695312H16.1776C15.9427 0.695312 15.7078 0.930246 15.7078 1.16518V9.6228C15.7078 9.85773 15.9427 10.0927 16.1776 10.0927ZM5.60562 10.0927H7.01522C7.25015 10.0927 7.48509 9.85773 7.48509 9.6228V7.03852C7.48509 6.80359 7.25015 6.56866 7.01522 6.56866H5.60562C5.37068 6.56866 5.13575 6.80359 5.13575 7.03852V9.6228C5.13575 9.85773 5.37068 10.0927 5.60562 10.0927ZM9.12962 10.0927H10.5392C10.7742 10.0927 11.0091 9.85773 11.0091 9.6228V2.33985C11.0091 2.10492 10.7742 1.86998 10.5392 1.86998H9.12962C8.89469 1.86998 8.65975 2.10492 8.65975 2.33985V9.6228C8.65975 9.85773 8.89469 10.0927 9.12962 10.0927ZM18.6444 12.442H2.78641V1.28265C2.78641 0.958145 2.52358 0.695312 2.19908 0.695312H1.02441C0.699905 0.695312 0.437073 0.958145 0.437073 1.28265V13.6167C0.437073 14.2653 0.963104 14.7913 1.61174 14.7913H18.6444C18.9689 14.7913 19.2318 14.5285 19.2318 14.204V13.0293C19.2318 12.7048 18.9689 12.442 18.6444 12.442Z" fill="#444444"/>
            </svg>
              <span className='m-2'>Reporte</span>
            </button>
            </div>
          </div>
          <div className='linea  my-4'></div>
        </div>
    </div>
  )
}

export default Campain

