/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { useCallback, useEffect, useState } from 'react'
import { FormProvider, useForm, Controller } from 'react-hook-form'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import InputGroup from 'react-bootstrap/InputGroup'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import Col from 'react-bootstrap/Col'
import { IoTimeOutline, IoReturnUpBack } from 'react-icons/io5'
import debounce from 'lodash.debounce'
import { CiImageOn } from 'react-icons/ci'
import { useNavigate, useParams } from 'react-router-dom'
import Select from 'react-select'
import { IMask } from 'react-imask'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { Loading } from '../../../Globals/Loading'
import InputSelectCustom from './InputSelectCustom'
import Service from '../../../../services'
import { RootState } from '../../../../state/store'
import SelectedContainer from './selectedContainer'
import ImagesContainer from './ImagesSelectContainer'
import ImagesSelectedContainer from './imagesSelectedContainer'
import MaskedInput from './maskInput'
import { TooltipPanel } from '../../../Globals/TooltipPanel'
import { ShowAlert } from '../../../Globals'
import { useClientContext } from '../../../../contexts/ClientContext'

interface imgI {
  urlmain: string
  id: number
  title: string
  urlmobile: string
  tags: string[]
  selected?: boolean
}

export interface selectedImgI extends imgI {
  order?: number
  link?: string
  updateLink?: boolean
  imgMobileMode?: boolean
}

interface tagI {
  Id: number
  Name: string
  dateAdded: string
  dateUpdated: string
}

interface FormDataI {
  id?: number
  idScriptClient: number
  hash: string
  name: any
  title: any
  enabled: any
  enabledDateTime: any
  timeStart: string
  timeEnd: string
  dateStart: string
  dateEnd: string
  tagsSelected: any
  salesChannels: {
    codes: string[]
  }
  whitelabels: {
    codes: string[]
  }
  audiences: {
    ids: number[]
  }
  images: any[]
}
const SmartImageFormSchema = yup.object().shape({
  block: yup.object().shape({
    label: yup.string().required(),
    value: yup.string().required()
  }),
  title: yup.string().required(),
  enabled: yup.mixed().oneOf([0, 1]).required(),
  enabledDateTime: yup.mixed().oneOf([0, 1]).required(),
  dateStart: yup.string().notRequired(),
  timeStart: yup.string().notRequired(),
  dateEnd: yup.string().notRequired(),
  timeEnd: yup.string().notRequired(),
  salesChannel: yup.array().of(
    yup.object().shape({
      label: yup.string(),
      value: yup.string()
    })
  ),
  cdp: yup.array().of(
    yup.object().shape({
      label: yup.string(),
      value: yup.string()
    })
  ),
  whiteLabels: yup.array().of(
    yup.object().shape({
      label: yup.string(),
      value: yup.string()
    })
  )
})

type imgListOrder = Record<string, imgI[]>

export const SmartImagesBindsForm = () => {
  const { selectedScript, selectedClient } = useClientContext()
  const userData = useSelector<RootState, any>(
    (state: RootState) => state.userAuth
  )
  const user = userData.data
  const [tags, setTags] = useState<tagI[]>([])
  const [modeStatus, setModeStatus] = useState<boolean>(false)
  const [modeExpire, setModeExpire] = useState<boolean>(false)
  const [CDP, setCDP] = useState([])
  const [images, setImages] = useState<imgListOrder>({})
  const [salesChannels, setSalesChannels] = useState<number | string>('')
  const [whiteLabels, setWhiteLabels] = useState<string>('')
  const [selectedImages, setSelectedImages] = useState<selectedImgI[]>([])
  const navigate = useNavigate()
  const { block, id } = useParams()

  const methods = useForm({
    resolver: yupResolver(SmartImageFormSchema)
  })

  const intl = useIntl()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isUpdate, setIsUpdate] = useState<boolean>(() => (id ? true : false))
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [updateData, setUpdateData] = useState<any>({})
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingImages, setLoadingImages] = useState<boolean>(false)

  const blockOptions = [
    { value: 'BDW-HOME-IMAGES-1', label: 'BDW-HOME-IMAGES-1' },
    { value: 'BDW-HOME-IMAGES-2', label: 'BDW-HOME-IMAGES-2' },
    { value: 'BDW-HOME-IMAGES-3', label: 'BDW-HOME-IMAGES-3' },
    { value: 'BDW-HOME-IMAGES-4', label: 'BDW-HOME-IMAGES-4' },
    { value: 'BDW-HOME-IMAGES-5', label: 'BDW-HOME-IMAGES-5' },
    { value: 'BDW-PDP-IMAGES-1', label: 'BDW-PDP-IMAGES-1' },
    { value: 'BDW-PLP-IMAGES-1', label: 'BDW-PLP-IMAGES-1' },
    { value: 'BDW-SEARCH-IMAGES-1', label: 'BDW-SEARCH-IMAGES-1' },
    {
      value: 'BDW-SEARCHNORESULT-IMAGES-1',
      label: 'BDW-SEARCHNORESULT-IMAGES-1'
    }
  ]

  const TIME_MASK = [
    {
      mask: ''
    },
    {
      overwrite: true,
      autofix: true,
      mask: 'HH:MM',
      blocks: {
        HH: {
          mask: IMask.MaskedRange,
          placeholderChar: 'HH',
          from: 0,
          to: 23,
          maxLength: 2
        },
        MM: {
          mask: IMask.MaskedRange,
          placeholderChar: 'MM',
          from: 0,
          to: 59,
          maxLength: 2
        }
      }
    }
  ]
  const handleUrlSelectImage = useCallback(
    (e, imageId) => {
      const newList = [...selectedImages]
      const urlImageUpdate = newList.find((img) => img.id === imageId)
      if (!urlImageUpdate) return
      urlImageUpdate.link = e.target.value
      setSelectedImages(newList)
    },
    [selectedImages]
  )
  const handleSelectImages = useCallback(
    (img: imgI, tag: string) => {
      const newTagImages = images[tag].map((i: imgI) => {
        if (img.id === i.id) {
          // i.selected = true
          if (!selectedImages.some((im) => im.id === img.id)) {
            const newImagenSelected: selectedImgI = i
            newImagenSelected.imgMobileMode = false
            newImagenSelected.order = selectedImages.length + 1
            setSelectedImages([...selectedImages, newImagenSelected])
          }
          return i
        }
        return i
      })
      images[tag] = newTagImages
      setImages(() => images)
    },
    [images, selectedImages, methods.watch('tagFilter')]
  )

  const handleRemoveSelectedImage = useCallback(
    (id: number) => {
      setSelectedImages(selectedImages.filter((i) => i.id !== id))
    },
    [selectedImages]
  )

  const handleImgOrder = useCallback(
    (newOrder: number[]) => {
      newOrder.forEach((img, idx) => {
        selectedImages[img].order = idx + 1
      })
      setSelectedImages([...selectedImages])
    },
    [selectedImages]
  )

  const changeImgMode = useCallback(
    (id: number, mode: boolean) => {
      setSelectedImages(
        selectedImages.map((img) => {
          if (img.id === id) {
            img.imgMobileMode = mode
            return img
          }
          return img
        })
      )
    },
    [selectedImages]
  )

  const submitForm = useCallback(
    async (formData) => {
      if (!selectedScript) {
        return
      }
      if (!selectedClient) {
        return
      }
      if (formData.enabledDateTime == 1) {
        if (!formData.dateStart) {
          methods.setError('dateStart', { type: 'required' })
          methods.setFocus('dateStart')

          return
        }
        if (!formData.dateEnd) {
          methods.setError('dateEnd', { type: 'required' })
          methods.setFocus('dateEnd')

          return
        }
        const dateStart = formData.dateStart.split('-')
        const dateEnd = formData.dateEnd.split('-')
        if (
          new Date() >
          new Date(
            parseInt(dateEnd[0]),
            parseInt(dateEnd[1]) - 1,
            parseInt(dateEnd[2]),
            23,
            59
          )
        ) {
          methods.setError('dateEnd', { type: 'required' })
          methods.setFocus('dateEnd')

          return
        }
        if (
          new Date(
            parseInt(dateStart[0]),
            parseInt(dateStart[1]) - 1,
            parseInt(dateStart[2]),
            23,
            59
          ) >
          new Date(
            parseInt(dateEnd[0]),
            parseInt(dateEnd[1]) - 1,
            parseInt(dateEnd[2]),
            23,
            59
          )
        ) {
          methods.setError('dateEnd', { type: 'required' })
          methods.setFocus('dateEnd')
          return
        }

        if (formData.timeStart.length !== 4) {
          methods.setError('dateStart', { type: 'required' })
          methods.setFocus('timeStart')
          return
        }

        if (formData.timeEnd.length !== 4) {
          methods.setError('dateEnd', { type: 'required' })
          methods.setFocus('timeEnd')
          return
        }
        if (
          formData.dateStart === formData.dateEnd &&
          parseInt(formData.timeStart) >= parseInt(formData.timeEnd)
        ) {
          methods.setError('dateStart', { type: 'required' })
          methods.setFocus('timeStart')
          return
        }
      }

      if (whiteLabels.length) {
        methods.setFocus('whiteLabels')
        return
      }
      const data: FormDataI = {
        idScriptClient: selectedClient?.idClient,
        hash: selectedScript?.idHashScript,
        name: formData.block.label,
        title: formData.title,
        enabled: formData.enabled,
        enabledDateTime: formData.enabledDateTime,
        dateStart: formData.dateStart,
        timeStart: formData.timeStart
          ? `${formData.timeStart.slice(0, 2)}:${formData.timeStart.slice(2)}`
          : '',
        dateEnd: formData.dateEnd,
        timeEnd: formData.timeEnd
          ? `${formData.timeEnd.slice(0, 2)}:${formData.timeEnd.slice(2)}`
          : '',
        tagsSelected: formData.tags
          .filter((tag: any) => {
            return selectedImages
              .reduce((acc: string[], cu) => {
                acc.push(...cu.tags)
                return acc
              }, [])
              .some((selectedTagImages) => tag.label === selectedTagImages)
          })
          .map((tag: any) => tag.label),
        salesChannels: {
          codes:
            formData.salesChannel && formData.salesChannel.length > 0
              ? formData.salesChannel.map((ch: any) => ch.label)
              : []
        },
        whitelabels: {
          codes:
            formData.whiteLabels && formData.whiteLabels.length > 0
              ? formData.whiteLabels.map((w: any) => w.label)
              : []
        },
        audiences: {
          ids: formData.cdp.map((w: any) => parseInt(w.value))
        },
        images: selectedImages.map((img) => ({
          id: img.id,
          priority: img.order,
          title: img.title,
          link: img.link
        }))
      }
      if (isUpdate && id) {
        data.id = parseInt(id)
      }
      try {
        const { value, isConfirmed } = await ShowAlert({
          title: intl.formatMessage({
            id: 'app.modal.token.title',
            defaultMessage: 'Ingrese el <strong>token</strong>'
          }),
          input: 'text',
          html:
            '<span class="m-4 text-left">' +
            intl.formatMessage({
              id: 'app.modal.token.texto',
              defaultMessage: 'Ingrese el <strong>token</strong>'
            }) +
            '</span>',
          inputLabel: '',
          inputPlaceholder: 'Token',
          color: '#1c684c',
          confirmButtonText: intl.formatMessage({
            id: 'app.modal.btn.enviar',
            defaultMessage: 'Enviar'
          }),
          showCancelButton: true,
          cancelButtonText: intl.formatMessage({
            id: 'app.modal.btn.cerrar',
            defaultMessage: 'Cerrar'
          }),
          width: 600,
          inputValidator: (result) => {
            if (result === '') {
              return intl.formatMessage({
                id: 'app.token-inexistente',
                defaultMessage: 'El token no fue informado.'
              })
            } else {
              return ''
            }
          }
        })
        if (!isConfirmed) return null
        let url: string = `SmartImage/AddCarousel?idHashScript=${selectedScript?.idHashScript}`
        if (isUpdate) {
          url = `SmartImage/UpdateCarousel?idHashScript=${selectedScript?.idHashScript}`
        }
        const { data: dataResponse, status } = await Service.post<any, any>(
          url,
          data,
          {
            method: 'POST',
            headers: {
              token: user.token,
              userName: user.username,
              tokenGoogle: value,

              'Content-Type': 'application/json',
              Accept: '*/*',
              credentials: 'include'
            }
          }
        )
        if (status == 200 && dataResponse.StatusCode == 0) {
          ShowAlert({
            title: 'Imágenes inteligentes',
            text: dataResponse.Message,
            icon: 'success',
            showConfirmButton: true,
            timer: 2500,
            closeButtonHtml: intl.formatMessage(
              { id: 'app.modal.btn.cerrar' },
              { defaultMessage: 'Cerrar' }
            ),
            confirmButtonText: intl.formatMessage(
              { id: 'app.modal.btn.cerrar' },
              { defaultMessage: 'Cerrar' }
            ),
            toast: true,
            width: 500
          })
          return navigate(
            `/dashboard/smart-images/setup-smart-images-listByBlock/${formData.block.label}`
          )
        }
      } catch (error) {
        console.log(error)
      }
      // navigate(`/dashboard/smart-images/setup-smart-images`) // this only while is mock
    },
    [selectedImages, isUpdate, id, whiteLabels]
  )

  const filterOptions = (inputValue: string) => {
    return tags
      .map((tag) => {
        return { value: tag.Id, label: tag.Name }
      })
      .filter((i: any) =>
        i.label.toLowerCase().includes(inputValue.toLowerCase())
      )
  }

  const promiseOptions = async (inputValue: string) =>
    await new Promise<any[]>((resolve) => {
      setTimeout(() => {
        resolve(filterOptions(inputValue))
      }, 1000)
    })

  const getDataWithDebounce = debounce((cdpFilterText, cb) => {
    getDataCDP(cdpFilterText, cb)
  }, 800)

  const getDataCDP = async (cdpFilterText: string, cb: any) => {
    try {
      const { data } = await Service.get<any>(
        `Automation/GetAllSegmentsCDP?idHashScript=${
          selectedScript?.idHashScript
        }&page=1&per_page=10&filterText=${cdpFilterText.toLowerCase()}&sortField=idSegment&sortDirection=asc`,
        {
          headers: {
            token: user.token
          }
        }
      )
      if (data?.Entities) {
        return cb(
          // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
          data.Entities.length
            ? data.Entities.map((cdp: any) => {
                return { value: cdp.IdSegment, label: cdp.AliasName }
              })
            : []
        )
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getTagsByClient = useCallback(async () => {
    try {
      const { data } = await Service.get<any>(
        `Automation/Tags/GetAll?idHashScript=${selectedScript?.idHashScript}`,
        {
          headers: {
            token: user.token
          }
        }
      )

      if (data) {
        // setTags(tagsMock)
        // console.log(data)
        setTags(data)
      }
    } catch (error) {
      console.log(error)
    }
  }, [selectedScript, user])

  const getSmartImages = useCallback(
    async (tagFilter) => {
      try {
        // setLoading(true)
        setLoadingImages(true)
        const { data } = await Service.get<any>(
          `Automation/Images/FindAll?idHashScript=${
            selectedScript?.idHashScript
          }&operador=${tagFilter ? 'and' : 'or'}${methods
            .watch('tags')
            .map((tag: any) => `&tag=${tag.label}`)
            .join('')}`, // hardcoded
          {
            headers: {
              token: user.token
            }
          }
        )
        if (data.StatusCode < 1) {
          if (tagFilter) {
            const imgs: imgListOrder = {}
            imgs.images = data.Entities
            setImages(imgs)
            setLoadingImages(false)
            return
          }
          setImages(
            methods.watch('tags').reduce((acc: any, cu: any) => {
              if (!acc.ids) {
                acc.ids = data.Entities.map((a: imgI) => a.id)
              }
              if (!acc[cu.label]) {
                acc[cu.label] = []
              }
              acc[cu.label].push(
                ...data.Entities.filter(
                  (ar: any) =>
                    acc.ids.includes(ar.id) && ar.tags.includes(cu.label)
                )
              )
              acc.ids = acc.ids.filter(
                (e: any) => !acc[cu.label].map((a: any) => a.id).includes(e)
              )
              return acc
            }, {})
          )
          if (isUpdate) {
            const dataImg = updateData.images
              .filter((a: imgI) => data.Entities.some((i: any) => i.id == a.id))
              .map((i: any) => ({
                ...i,
                order: i.priority,
                imgMobileMode: false,
                urlmain: i.urldesktop,
                link: i.link
              }))
              .sort((a: any, b: any) => a.priority - b.priority)
            setSelectedImages(dataImg)
          }
          setLoadingImages(false)
        }
      } catch (error) {
        setLoadingImages(false)
        ShowAlert({
          title: 'Carousel de imágenes inteligentes',
          text: 'Ops! ocurrió un error al solicitar la data, refresca la pagina o vuelve en unos minutos',
          icon: 'warning',
          showConfirmButton: true,
          timer: 2500,
          closeButtonHtml: intl.formatMessage({
            id: 'app.modal.btn.cerrar',
            defaultMessage: 'Cerrar'
          }),
          confirmButtonText: intl.formatMessage({
            id: 'app.modal.btn.cerrar',
            defaultMessage: 'Cerrar'
          }),
          toast: true,
          width: 500
        })
        // setLoading(false)
        console.log(error)
      }
    },
    [selectedScript, methods.watch('tags'), user, updateData]
  )

  const handleSalesChannels = useCallback((e) => {
    e.preventDefault()
    // if (isNaN(e.target.value)) return
    setSalesChannels(e.target.value.trim().replace(/\s/g, ''))
  }, [])

  const addSalesChannels = useCallback((e: any, click = false) => {
    if (e.target.value.length === 0) return
    if (click) {
      e.preventDefault()
      if (!methods.getValues('salesChannel')) {
        methods.setValue('salesChannel', [
          { label: e.target.value, value: e.target.value }
        ])
        methods.clearErrors('salesChannel')
        setSalesChannels('')
        return
      }

      methods.setValue('salesChannel', [
        ...methods.getValues('salesChannel'),
        { label: e.target.value, value: e.target.value }
      ])
      setSalesChannels('')
      return
    }
    switch (e.key) {
      case 'Enter':
      case 'Tab':
        e.preventDefault()
        if (!methods.getValues('salesChannel')) {
          methods.setValue('salesChannel', [
            { label: e.target.value, value: e.target.value }
          ])
          methods.clearErrors('salesChannel')
          setSalesChannels('')
          return
        }

        methods.setValue('salesChannel', [
          ...methods.getValues('salesChannel'),
          { label: e.target.value, value: e.target.value }
        ])
        setSalesChannels('')
    }
  }, [])

  const handleWhiteLabels = useCallback((e) => {
    e.preventDefault()
    setWhiteLabels(e.target.value)
  }, [])

  const addWhiteLabels = useCallback((e: any, click = false) => {
    if (e.target.value.length === 0) return
    if (click) {
      e.preventDefault()
      if (!methods.getValues('whiteLabels')) {
        methods.setValue('whiteLabels', [
          { label: e.target.value, value: e.target.value }
        ])
        methods.clearErrors('whiteLabels')
        setWhiteLabels('')
        return
      }

      methods.setValue('whiteLabels', [
        ...methods.getValues('whiteLabels'),
        { label: e.target.value, value: e.target.value }
      ])
      setWhiteLabels('')
      return
    }
    switch (e.key) {
      case 'Enter':
      case 'Tab':
        e.preventDefault()
        if (!methods.getValues('whiteLabels')) {
          methods.setValue('whiteLabels', [
            { label: e.target.value, value: e.target.value }
          ])
          methods.clearErrors('whiteLabels')
          setWhiteLabels('')
          return
        }

        methods.setValue('whiteLabels', [
          ...methods.getValues('whiteLabels'),
          { label: e.target.value, value: e.target.value }
        ])
        setWhiteLabels('')
    }
  }, [])

  const getSmartImageCarouselById = useCallback(async () => {
    try {
      setLoading(true)
      const { data } = await Service.get<any>(
        `/SmartImage/GetContentCarousel?idHashScript=${selectedScript?.idHashScript}&id=${id}`,
        {
          headers: {
            token: user.token
          }
        }
      )
      if (data.StatusCode === 0) {
        setUpdateData(data.Entity)
        methods.setValue('name', {
          label: data.Entity.name,
          value: data.Entity.name
        })
        methods.setValue('title', data.Entity.title)
        methods.setValue('enabled', data.Entity.enabled)
        setModeStatus(data.Entity.enabled == 1 ? true : false)
        methods.setValue('enabledDateTime', data.Entity.enabledDateTime)
        setModeExpire(data.Entity.enabledDateTime == 1 ? true : false)
        methods.setValue(
          'salesChannel',
          data.Entity.saleschannels.codes.map((ch: string) => ({
            label: ch,
            value: ch
          }))
        )
        methods.setValue(
          'whiteLabels',
          data.Entity.whitelabels.codes.map((lb: string) => ({
            label: lb,
            value: lb
          }))
        )
        methods.setValue(
          'cdp',
          data.Entity.audiences
            ? data.Entity.audiences.map(
                (cdp: { id: number; name: string }) => ({
                  label: cdp.name,
                  value: cdp.id
                })
              )
            : []
        )
        methods.setValue(
          'tags',
          data.Entity.tagsSelected.map((tag: string) => ({
            label: tag,
            value: tag
          }))
        )

        const dateStart: string[] | string = data.Entity.dateStart.length
          ? data.Entity.dateStart.split(' ')[0].split('/')
          : ''

        const dateEnd: string[] | string = data.Entity.dateEnd.length
          ? data.Entity.dateEnd.split(' ')[0].split('/')
          : ''

        const timeStart: string[] | string = data.Entity.timeStart.length
          ? data.Entity.timeStart.split(' ')[0].split(':')
          : ''

        const timeEnd: string[] | string = data.Entity.timeEnd.length
          ? data.Entity.timeEnd.split(' ')[0].split('/')
          : ''

        methods.setValue(
          'timeStart',
          typeof timeStart === 'string' ? '' : timeStart[0].concat(timeStart[1])
        )

        methods.setValue(
          'timeEnd',
          typeof timeEnd === 'string' ? '' : timeEnd[0].concat(timeEnd[1])
        )

        methods.setValue(
          'dateEnd',
          typeof dateEnd === 'string'
            ? ''
            : `${dateEnd[2]}-${
                dateEnd[0].length === 1
                  ? '0'.concat('', dateEnd[0])
                  : dateEnd[0]
              }-${
                dateEnd[1].length === 1
                  ? '0'.concat('', dateEnd[1])
                  : dateEnd[1]
              }`
        )
        methods.setValue(
          'dateStart',
          typeof dateStart === 'string'
            ? ''
            : `${dateStart[2]}-${
                dateStart[0].length === 1
                  ? '0'.concat('', dateStart[0])
                  : dateStart[0]
              }-${
                dateStart[1].length === 1
                  ? '0'.concat('', dateStart[1])
                  : dateStart[1]
              }`
        )
      }
      // methods.setValue('', data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }, [id, images])

  useEffect(() => {
    getDataCDP('', setCDP)
    getTagsByClient()
    methods.setValue('tagFilter', false)
    if (!isUpdate) {
      methods.setValue('enabledDateTime', 0)
      methods.setValue('enabled', 0)
    }
    methods.setValue('cdp', [])
    methods.setValue('tags', [])
    if (isUpdate || block) {
      methods.setValue('block', {
        label: block,
        value: block
      })
    }
    if (isUpdate) {
      getSmartImageCarouselById()
    }
    return () => {}
  }, [isUpdate, block])

  useEffect(() => {
    if (methods.watch('tags') && methods.watch('tags').length) {
      getSmartImages(methods.watch('tagFilter'))
    }
  }, [methods.watch('tags'), methods.watch('tagFilter')])
  return (
    <div className="container mg-t-30" style={{ display: 'block' }}>
      <div className="row justify-content-md-center">
        {loading && isUpdate ? (
          <Loading
            textLoading={intl.formatMessage(
              { id: 'app.vitrinas-spinner.espere' },
              { defaultMessage: 'Espere...' }
            )}
          />
        ) : (
          <>
            <div>
              <div
                style={{
                  display: 'flex',
                  marginBottom: 8,
                  alignItems: 'center'
                }}
              >
                <div
                  style={{
                    height: 40,
                    width: 40,
                    borderRadius: '100%',
                    backgroundColor: '#2BAE7E',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginRight: 12
                  }}
                >
                  <CiImageOn size={24} color="#fff" />
                </div>
                <h2 style={{ fontSize: 18, margin: 0, color: '#444' }}>
                  Crear nuevo bloque de imágenes
                </h2>
              </div>
              <p style={{ color: '#666' }}>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Explicabo, cupiditate officiis. Dolorem veniam vero ad quam
                minus in doloribus, cum natus sint necessitatibus repellat, eum
                maxime nam, unde corrupti non?
              </p>
            </div>
            <div
              style={{
                height: 30,
                width: 30,
                borderRadius: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: 0,
                border: '1px solid #F29718',
                cursor: 'pointer'
              }}
              onClick={() =>
                navigate(
                  block
                    ? `/dashboard/smart-images/setup-smart-images-listByBlock/${block}`
                    : `/dashboard/smart-images/setup-smart-images`
                )
              }
            >
              <IoReturnUpBack size={20} color="#F29718" />
            </div>
            <Col>
              <FormProvider {...methods}>
                <Form
                  style={{ padding: '2rem' }}
                  // eslint-disable-next-line @typescript-eslint/no-misused-promises
                  onSubmit={methods.handleSubmit(submitForm)}
                >
                  <div
                    className="row"
                    style={{
                      backgroundColor: '#fff',
                      marginBottom: '3rem',
                      padding: '2rem 0'
                    }}
                  >
                    <Col>
                      <div className="row" style={{ paddingInline: '2rem' }}>
                        <Form.Group
                          className="mb-3 col-sm-5"
                          controlId="block-name"
                        >
                          <Form.Label>
                            Bloque de imagen{' '}
                            <TooltipPanel
                              ObjectTooltip={user.tooltips.find(
                                (t: any) =>
                                  t.idTooltip ===
                                  'app.form.smart.image.form.label.block'
                              )}
                            />
                          </Form.Label>
                          <Controller
                            name="block"
                            render={({ field: { onChange } }) => (
                              <Select
                                options={blockOptions}
                                defaultValue={blockOptions.find(
                                  (a) => a.value === block
                                )}
                                styles={{
                                  control: (prevStyles) => ({
                                    ...prevStyles,
                                    minHeight: '33.5px',
                                    border: `1px solid ${
                                      methods.formState.errors.block
                                        ? '#DB164B'
                                        : '#ced4da'
                                    }`,
                                    height: '33.5px',
                                    backgroundColor: '#fff !important'
                                  }),
                                  valueContainer: (prevStyles) => ({
                                    ...prevStyles,
                                    padding: '0px 8px'
                                  }),
                                  placeholder: (prevStyles) => ({
                                    ...prevStyles,
                                    fontSize: 14,
                                    fontStyle: methods.formState.errors.block
                                      ? 'italic'
                                      : 'normal',
                                    color: methods.formState.errors.block
                                      ? '#DB164B'
                                      : 'hsl(0, 0%, 50%)'
                                  })
                                }}
                                placeholder={
                                  methods.formState.errors.block
                                    ? 'Debe seleccionar el bloque'
                                    : 'Selecciona el bloque'
                                }
                                onChange={onChange}
                              />
                            )}
                            control={methods.control}
                          />
                        </Form.Group>

                        <Form.Group className="mb-3 col-sm-5" controlId="title">
                          <Form.Label>
                            Titulo / Descripción{' '}
                            <TooltipPanel
                              ObjectTooltip={user.tooltips.find(
                                (t: any) =>
                                  t.idTooltip ===
                                  'app.form.smart.image.form.label.title'
                              )}
                            />
                          </Form.Label>
                          <Form.Control
                            type="text"
                            {...methods.register('title')}
                            style={{
                              border: `1px solid ${
                                methods.formState.errors.title
                                  ? '#DB164B'
                                  : '#ced4da'
                              }`
                            }}
                            className={
                              methods.formState.errors.title ? 'is-invalid' : ''
                            }
                            aria-describedby="title"
                            autoComplete="off"
                            placeholder={
                              methods.formState.errors.title
                                ? 'Debe ingresar un titulo al carousel'
                                : 'Carousel de Imagenes ejemplo'
                            }
                          />
                        </Form.Group>
                        <Form.Group
                          className="mb-3 col-sm-2"
                          controlId="status"
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <Form.Label>
                            Activar{' '}
                            <TooltipPanel
                              ObjectTooltip={user.tooltips.find(
                                (t: any) =>
                                  t.idTooltip ===
                                  'app.form.smart.image.form.label.enable'
                              )}
                            />
                          </Form.Label>
                          <label className="switch_mode col-1 col-lg-1 col-md-1 col-sm-1 col-xs-1">
                            <input
                              className=""
                              type="checkbox"
                              id="switch-modes"
                              onChange={() => {
                                methods.setValue('enabled', !modeStatus ? 1 : 0)
                                setModeStatus(!modeStatus)
                              }}
                              checked={modeStatus}
                              defaultChecked={false}
                            />
                            <span
                              className={
                                modeStatus
                                  ? 'slider-mode round slider-bckg-on'
                                  : 'slider-mode round slider-bckg-off'
                              }
                            >
                              <small className="switch_m">
                                {modeStatus ? 'ON' : 'OFF'}
                              </small>
                            </span>
                          </label>
                        </Form.Group>
                      </div>
                    </Col>

                    <div
                      style={{
                        width: '100%',
                        height: 1,
                        backgroundColor: '#DBDBDB',
                        marginTop: '1.8rem',
                        marginBottom: '1.8rem'
                      }}
                    ></div>
                    <Col>
                      <div className="row" style={{ padding: '1.3rem 2rem' }}>
                        <Form.Group
                          className="col-sm-4"
                          controlId="Vencimiento"
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center'
                          }}
                        >
                          <Form.Label
                            style={{ marginBottom: 0, marginRight: 8 }}
                          >
                            Vencimiento{' '}
                            <TooltipPanel
                              ObjectTooltip={user.tooltips.find(
                                (t: any) =>
                                  t.idTooltip ===
                                  'app.form.smart.image.form.label.dateExpireEnabled'
                              )}
                            />
                          </Form.Label>
                          <label className="switch_mode col-1 col-lg-1 col-md-1 col-sm-1 col-xs-1">
                            <input
                              className=""
                              type="checkbox"
                              id="switch-modes"
                              onChange={() => {
                                methods.setValue(
                                  'enabledDateTime',
                                  !modeExpire ? 1 : 0
                                )
                                methods.clearErrors('dateStart')
                                methods.clearErrors('dateEnd')
                                setModeExpire(!modeExpire)
                              }}
                              checked={modeExpire}
                              defaultChecked={false}
                            />
                            <span
                              className={
                                modeExpire
                                  ? 'slider-mode round slider-bckg-on'
                                  : 'slider-mode round slider-bckg-off'
                              }
                            >
                              <small className="switch_m">
                                {modeExpire ? 'ON' : 'OFF'}
                              </small>
                            </span>
                          </label>
                        </Form.Group>
                        {modeExpire && (
                          <>
                            <Form.Group
                              className="col-sm-4"
                              controlId="dateStart"
                              style={{
                                display: 'grid',
                                gridTemplateColumns: 'repeat(6, auto)',
                                gridTemplateRows: methods.formState.errors
                                  .dateStart
                                  ? `repeat(2, 25.5px)`
                                  : '25.5px',
                                gridColumnGap: '0px',
                                gridRowGap: '0px',
                                alignContent: 'center'
                              }}
                            >
                              <Form.Label
                                style={{
                                  marginBottom: 0,
                                  marginRight: 8,
                                  gridArea: '1 / 1 / 2 / 2',
                                  placeSelf: 'center'
                                }}
                              >
                                Desde
                              </Form.Label>
                              <Form.Control
                                style={{
                                  border: `1px solid ${
                                    methods.formState.errors.dateStart
                                      ? '#DB164B'
                                      : '#ced4da'
                                  }`,
                                  color: methods.formState.errors.dateStart
                                    ? '#DB164B'
                                    : 'hsl(0, 0%, 50%)',
                                  borderTopRightRadius: 0,
                                  borderBottomRightRadius: 0,
                                  gridArea: '1 / 2 / 2 / 5'
                                }}
                                type="date"
                                {...methods.register('dateStart')}
                                aria-describedby="dateStart"
                              />
                              <Controller
                                name="timeStart"
                                control={methods.control}
                                render={({ field: { onChange, value } }) => (
                                  <MaskedInput
                                    defaultValue={value || 1200}
                                    onChange={onChange}
                                    mask={TIME_MASK}
                                    placeholder="HH:MM"
                                    style={{
                                      border: '1px solid #ccc',
                                      borderRadius: '5px',
                                      padding: '2px 10px',
                                      margin: '0px 0',
                                      color: '#37393a',
                                      width: '72px',
                                      borderTopLeftRadius: 0,
                                      borderBottomLeftRadius: 0,
                                      gridArea: '1 / 5 / 2 / 6'
                                    }}
                                  />
                                )}
                              />
                              <IoTimeOutline
                                style={{
                                  marginLeft: 3,
                                  gridArea: '1 / 6 / 2 / 7'
                                }}
                                size={25}
                                color="#F29718"
                              />
                              {methods.formState.errors?.dateStart ? (
                                <span
                                  style={{
                                    color: '#DB164B',
                                    fontSize: 11,
                                    fontStyle: 'italic',
                                    gridArea: '2 / 2 / 3 / 5',
                                    alignSelf: 'center',
                                    justifySelf: 'center'
                                  }}
                                >
                                  {' '}
                                  Ingresa una fecha valida
                                </span>
                              ) : null}
                            </Form.Group>
                            <Form.Group
                              className="col-sm-4"
                              controlId="dateEnd"
                              style={{
                                display: 'grid',
                                gridTemplateColumns: 'repeat(6, auto)',
                                gridTemplateRows: methods.formState.errors
                                  .dateEnd
                                  ? `repeat(2, 25.5px)`
                                  : '25.5px',
                                gridColumnGap: '0px',
                                gridRowGap: '0px',
                                alignContent: 'center'
                              }}
                            >
                              <Form.Label
                                style={{
                                  marginBottom: 0,
                                  marginRight: 8,
                                  gridArea: '1 / 1 / 2 / 2',
                                  placeSelf: 'center'
                                }}
                              >
                                Hasta
                              </Form.Label>
                              <Form.Control
                                type="date"
                                style={{
                                  border: `1px solid ${
                                    methods.formState.errors.dateEnd
                                      ? '#DB164B'
                                      : '#ced4da'
                                  }`,
                                  color: methods.formState.errors.dateEnd
                                    ? '#DB164B'
                                    : 'hsl(0, 0%, 50%)',
                                  borderTopRightRadius: 0,
                                  borderBottomRightRadius: 0,
                                  gridArea: '1 / 2 / 2 / 5'
                                }}
                                {...methods.register('dateEnd')}
                                aria-describedby="to"
                              />

                              <Controller
                                name="timeEnd"
                                control={methods.control}
                                render={({ field: { onChange, value } }) => (
                                  <MaskedInput
                                    defaultValue={value || 1200}
                                    onChange={onChange}
                                    mask={TIME_MASK}
                                    placeholder="HH:MM"
                                    style={{
                                      border: '1px solid #ccc',
                                      borderRadius: '5px',
                                      padding: '2px 10px',
                                      margin: '0px 0',
                                      color: '#37393a',
                                      width: '60px',
                                      borderTopLeftRadius: 0,
                                      borderBottomLeftRadius: 0,
                                      gridArea: '1 / 5 / 2 / 6'
                                    }}
                                  />
                                )}
                              />
                              <IoTimeOutline
                                style={{
                                  marginLeft: 3,
                                  gridArea: '1 / 6 / 2 / 7'
                                }}
                                size={25}
                                color="#F29718"
                              />
                              {methods.formState.errors?.dateEnd ? (
                                <span
                                  style={{
                                    color: '#DB164B',
                                    fontSize: 11,
                                    fontStyle: 'italic',
                                    gridArea: '2 / 2 / 3 / 5',
                                    alignSelf: 'center',
                                    justifySelf: 'center'
                                  }}
                                >
                                  {' '}
                                  Ingresa una fecha valida
                                </span>
                              ) : null}
                            </Form.Group>
                          </>
                        )}
                      </div>
                    </Col>
                    <div
                      style={{
                        width: '100%',
                        height: 1,
                        backgroundColor: '#DBDBDB',
                        marginTop: '1.8rem',
                        marginBottom: '1.8rem'
                      }}
                    ></div>
                    <Col>
                      <div className="row" style={{ padding: '1.3rem 2rem' }}>
                        <Col style={{ borderRight: '1px solid #dbdbdb' }}>
                          <Form.Label>
                            Sales Channels{' '}
                            <TooltipPanel
                              ObjectTooltip={user.tooltips.find(
                                (t: any) =>
                                  t.idTooltip ===
                                  'app.form.smart.image.form.label.channels'
                              )}
                            />
                          </Form.Label>
                          <InputGroup
                            className="mb-3 col"
                            // controlId="salesChannel"
                          >
                            <Form.Control
                              type="text"
                              value={salesChannels}
                              onChange={handleSalesChannels}
                              onKeyDown={addSalesChannels}
                              style={{
                                border: `1px solid ${
                                  methods.formState.errors.salesChannel
                                    ? '#DB164B'
                                    : '#ced4da'
                                }`
                              }}
                              className={
                                methods.formState.errors.salesChannel
                                  ? 'is-invalid'
                                  : ''
                              }
                              aria-describedby="salesChannel"
                              placeholder={
                                methods.formState.errors.salesChannel
                                  ? 'Debe ingresar al menos un canal de venta'
                                  : 'Ingresa las sucursales'
                              }
                            />
                            <Button
                              variant="outline-secondary"
                              style={{
                                border: `1px solid ${
                                  methods.formState.errors.salesChannel
                                    ? '#DB164B'
                                    : '#ced4da'
                                }`
                              }}
                              onClick={(e: any) => {
                                e.target.value = salesChannels
                                return addSalesChannels(e, true)
                              }}
                            >
                              Agregar
                            </Button>
                          </InputGroup>
                          {methods.watch('salesChannel') &&
                            methods.watch('salesChannel').length > 0 && (
                              <Form.Group className="mb-3">
                                <Form.Label>
                                  Sales Channels Agregados{' '}
                                  <TooltipPanel
                                    ObjectTooltip={user.tooltips.find(
                                      (t: any) =>
                                        t.idTooltip ===
                                        'app.form.smart.image.form.label.channelsAdded'
                                    )}
                                  />
                                </Form.Label>
                                <SelectedContainer
                                  name="salesChannel"
                                  inline={true}
                                />
                              </Form.Group>
                            )}
                        </Col>
                        <Col>
                          <Form.Label>
                            WhiteLabel / Región{' '}
                            <TooltipPanel
                              ObjectTooltip={user.tooltips.find(
                                (t: any) =>
                                  t.idTooltip ===
                                  'app.form.smart.image.form.label.whitelabels'
                              )}
                            />
                          </Form.Label>
                          <InputGroup
                            className="mb-3 col"
                            // controlId="whiteLabels"
                          >
                            <Form.Control
                              name="whiteLabels"
                              type="text"
                              style={{
                                border: `1px solid ${
                                  methods.formState.errors.whiteLabels
                                    ? '#DB164B'
                                    : '#ced4da'
                                }`
                              }}
                              className={
                                methods.formState.errors.whiteLabels
                                  ? 'is-invalid'
                                  : ''
                              }
                              value={whiteLabels}
                              onChange={handleWhiteLabels}
                              onKeyDown={addWhiteLabels}
                              aria-describedby="whiteLabels"
                              placeholder={
                                methods.formState.errors.whiteLabels
                                  ? 'Debe ingresar al menos una region'
                                  : 'Ingresa las regiones'
                              }
                            />
                            <Button
                              variant="outline-secondary"
                              style={{
                                border: `1px solid ${
                                  methods.formState.errors.whiteLabels
                                    ? '#DB164B'
                                    : '#ced4da'
                                }`
                              }}
                              onClick={(e: any) => {
                                e.target.value = whiteLabels
                                return addWhiteLabels(e, true)
                              }}
                            >
                              Agregar
                            </Button>
                          </InputGroup>
                          {methods.watch('whiteLabels') &&
                            methods.watch('whiteLabels').length > 0 && (
                              <Form.Group className="mb-3">
                                <Form.Label>
                                  WhiteLabels Agregados{' '}
                                  <TooltipPanel
                                    ObjectTooltip={user.tooltips.find(
                                      (t: any) =>
                                        t.idTooltip ===
                                        'app.form.smart.image.form.label.whitelabelsAdded'
                                    )}
                                  />
                                </Form.Label>
                                <SelectedContainer
                                  name="whiteLabels"
                                  inline={true}
                                />
                              </Form.Group>
                            )}
                        </Col>
                      </div>
                    </Col>
                    <div
                      style={{
                        width: '100%',
                        height: 1,
                        backgroundColor: '#DBDBDB',
                        marginTop: '1.8rem',
                        marginBottom: '1.8rem'
                      }}
                    ></div>
                    <Col>
                      <div
                        className="row"
                        style={{ padding: '1.3rem 2rem 0rem 2rem' }}
                      >
                        <Col>
                          <Form.Group className="mb-3">
                            <Form.Label>
                              Audiencias{' '}
                              <TooltipPanel
                                ObjectTooltip={user.tooltips.find(
                                  (t: any) =>
                                    t.idTooltip ===
                                    'app.form.smart.image.form.label.cdp'
                                )}
                              />
                            </Form.Label>
                            <InputSelectCustom
                              name="cdp"
                              options={CDP}
                              isMulti={false}
                              placeholder="Selecciona Audiencias"
                              promiseOptions={getDataWithDebounce}
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          {methods.watch('cdp') &&
                            methods.watch('cdp').length > 0 && (
                              <Form.Group className="mb-3">
                                <Form.Label>
                                  Audiencias Seleccionadas{' '}
                                  <TooltipPanel
                                    ObjectTooltip={user.tooltips.find(
                                      (t: any) =>
                                        t.idTooltip ===
                                        'app.form.smart.image.form.label.cdpAdded'
                                    )}
                                  />
                                </Form.Label>
                                <SelectedContainer name="cdp" inline={false} />
                              </Form.Group>
                            )}
                        </Col>
                      </div>
                    </Col>
                  </div>
                  <div
                    className="row"
                    style={{ backgroundColor: '#fff', padding: '2rem' }}
                  >
                    <Form.Group className="mb-3">
                      <Form.Label>
                        Etiquetas de contenidos{' '}
                        <TooltipPanel
                          ObjectTooltip={user.tooltips.find(
                            (t: any) =>
                              t.idTooltip ===
                              'app.form.smart.image.form.label.tags'
                          )}
                        />
                      </Form.Label>
                      <InputSelectCustom
                        name="tags"
                        options={tags.map((tag) => ({
                          value: tag.Id.toString(),
                          label: tag.Name
                        }))}
                        isMulti={false}
                        placeholder="Buscar Etiquetas"
                        promiseOptions={promiseOptions}
                      />
                      <Form.Check // prettier-ignore
                        style={{
                          marginTop: '0.8rem'
                        }}
                        type={'checkbox'}
                        id={`default-unity`}
                        label={`Búsqueda por etiqueta individual`}
                        onChange={(e) => {
                          methods.setValue('tagFilter', e.target.checked)
                          setImages({})
                        }}
                      />
                    </Form.Group>
                    {methods.watch('tags') &&
                      methods.watch('tags').length > 0 && (
                        <Form.Group className="mb-3">
                          <Form.Label>
                            Etiquetas Seleccionadas{' '}
                            <TooltipPanel
                              ObjectTooltip={user.tooltips.find(
                                (t: any) =>
                                  t.idTooltip ===
                                  'app.form.smart.image.form.label.tagsAdded'
                              )}
                            />
                          </Form.Label>
                          <SelectedContainer name="tags" inline={false} />
                        </Form.Group>
                      )}
                    {methods.watch('tags') &&
                      !loadingImages &&
                      methods.watch('tags').length > 0 && (
                        <ImagesContainer
                          images={images}
                          selectImages={handleSelectImages}
                          selectedImages={selectedImages}
                          tagFilter={methods.watch('tagFilter')}
                          loadingImages={loadingImages}
                        />
                      )}
                  </div>
                  {selectedImages && selectedImages.length > 0 && (
                    <div
                      className="row"
                      style={{ backgroundColor: '#fff', padding: '2rem' }}
                    >
                      <ImagesSelectedContainer
                        images={selectedImages}
                        handleRemoveSelectedImage={handleRemoveSelectedImage}
                        changeImgMode={changeImgMode}
                        handleImgOrder={handleImgOrder}
                        handleUrlSelectImage={handleUrlSelectImage}
                      />
                    </div>
                  )}
                  <div className="row">
                    <div
                      style={{
                        marginTop: '2rem',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <Button
                        style={{
                          backgroundColor: '#2BAE7E',
                          marginRight: '1rem'
                        }}
                        type="submit"
                        // disabled={methods.formState.isSubmitting}
                      >
                        <FormattedMessage
                          id="app.smart-image.save"
                          defaultMessage={'Guardar'}
                        />
                      </Button>
                      <Button
                        style={{ backgroundColor: '#444' }}
                        onClick={() =>
                          navigate(`/dashboard/smart-images/setup-smart-images`)
                        }
                      >
                        Volver
                      </Button>
                    </div>
                  </div>
                </Form>
              </FormProvider>
            </Col>
          </>
        )}
      </div>
    </div>
  )
}
