import React, { useCallback, useMemo, useState } from 'react'
import './Prioridades.css'
import { useDrag } from '@use-gesture/react'
import { useSprings, animated, config } from '@react-spring/web'
import clamp from 'lodash.clamp'
import swap from 'lodash-move'
import styled from 'styled-components'
import { RxDragHandleDots2 } from 'react-icons/rx'
const HeadBlock = styled.div`
  margin: auto;
  font-weight: 500;
  color: #777;
  text-transform: uppercase;
  font-size: 14px;
`

const BodyBlock = styled.div`
  margin: auto;
  font-weight: 300;
  color: #444;
  font-size: 12px;
`

const StyledTableContainer = styled.div`
  background-color: #fff;
  height: -webkit-fill-available;
  margin-top: 20px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
`

const StyledRowContainerHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 4fr 3fr;
  padding: 10px 0;
  border-bottom: 1px solid #eee;
`

const StyledRowContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 4fr 3fr;
  position: absolute;
  width: 56rem;
  padding: 10px 0;
  border-bottom: 1px solid #eee;
`

interface BocksI {
  id: number
  name: string
  date: string
}

const fn =
  (order: number[], active = false, originalIndex = 0, curIndex = 0, y = 0) =>
  (index: number) =>
    active && index === originalIndex
      ? {
          y: curIndex * 90 + y,
          scale: 1.05,
          zIndex: 1,
          shadow: 15,
          immediate: (key: string) => key === 'zIndex',
          config: (key: string) => (key === 'y' ? config.stiff : config.default)
        }
      : {
          y: order.indexOf(index) * 90,
          scale: 1,
          zIndex: 0,
          shadow: 1,
          immediate: false
        }

const Prioridades = () => {
  const [blocks, setBlocks] = useState<BocksI[]>([
    {
      id: 1,
      name: 'CROSS SELLING COMPRA',
      date: '15 de Mayo de 2024, 11:30 hs.'
    },
    {
      id: 2,
      name: 'CARRUSEL HOME PERSONALIZADO',
      date: '15 de Mayo de 2024, 11:30 hs.'
    },
    {
      id: 3,
      name: 'SUSCRIPCIÓN NEWSLETTER',
      date: '15 de Mayo de 2024, 11:30 hs.'
    },
    {
      id: 4,
      name: 'PRUEBA HOT SALE BANCOS',
      date: '15 de Mayo de 2024, 11:30 hs.'
    }
  ])
  const [activeRowIndex, setActiveRowIndex] = useState(false)
  const order = useMemo(
    () => ({ current: blocks.map((_, index) => index) }),
    [blocks.length]
  )
  const [springs, api] = useSprings(blocks.length, fn(order.current))

  const handleImgOrder = useCallback(
    (newOrder: number[], lastOrder: number[]) => {
      if (lastOrder.toString() === newOrder.toString()) {
        return
      }
      setBlocks([...blocks])
    },
    [blocks]
  )

  const bind = useDrag(({ args: [originalIndex], active, movement: [, y] }) => {
    if (activeRowIndex) {
      const curIndex = order.current.indexOf(originalIndex)
      const curRow = clamp(
        Math.round((curIndex * 100 + y) / 100),
        0,
        blocks.length - 1
      )
      const newOrder = swap(order.current, curIndex, curRow)
      api.start(fn(newOrder, active, originalIndex, curIndex, y))
      if (!active) {
        const lastOrder = order.current
        order.current = newOrder
        handleImgOrder(newOrder, lastOrder)
      }
    }
  })

  return (
    <div>
      <div className="cfa-prioridad-container">
        <div className="cfa-prioridad-header">
          <h1>Establecer Prioridad</h1>
        </div>
        <div>
          <button className="cfa-button-guardar">Guardar</button>
        </div>
        <div className="cfa-grupos-envio">
          <div className="grupo-table">
          <StyledTableContainer>
            <h3 style={{ fontSize: '16px', marginBottom: '10px' }}>
              Grupo de envío 1
            </h3>
            <StyledRowContainerHeader>
              <HeadBlock>Mover</HeadBlock>
              <HeadBlock>Nombre</HeadBlock>
              <HeadBlock>Fecha</HeadBlock>
            </StyledRowContainerHeader>
            {springs.map(({ zIndex, shadow, y, scale }, i) => (
              <animated.div
                {...bind(i)}
                key={i}
                style={{
                  zIndex,
                  boxShadow: shadow.to(
                    (s) => `rgba(0, 0, 0, 0.15) 0px ${s}px ${2 * s}px 0px`
                  ),
                  y,
                  scale,
                  width: '100%',
                  touchAction: 'none'
                }}
              >
                <StyledRowContainer
                  style={{
                    height: 80
                  }}
                >
                  <BodyBlock>
                    <RxDragHandleDots2
                      cursor={'grab'}
                      onMouseDown={() => setActiveRowIndex(true)}
                      onMouseUp={() => setActiveRowIndex(false)}
                    />
                  </BodyBlock>
                  <BodyBlock style={{ fontSize: 14, textAlign: 'left' }}>
                    nombre
                  </BodyBlock>
                  <BodyBlock style={{ position: 'relative' }}>fecha</BodyBlock>
                </StyledRowContainer>
              </animated.div>
            ))}
          </StyledTableContainer>
          </div>
          <div className="grupo-table">
          <StyledTableContainer>
            <h3 style={{ fontSize: '16px', marginBottom: '10px' }}>
              Grupo de envío 2
            </h3>
            <StyledRowContainerHeader>
              <HeadBlock>Mover</HeadBlock>
              <HeadBlock>Nombre</HeadBlock>
              <HeadBlock>Fecha</HeadBlock>
            </StyledRowContainerHeader>
            {springs.map(({ zIndex, shadow, y, scale }, i) => (
              <animated.div
                {...bind(i)}
                key={i}
                style={{
                  zIndex,
                  boxShadow: shadow.to(
                    (s) => `rgba(0, 0, 0, 0.15) 0px ${s}px ${2 * s}px 0px`
                  ),
                  y,
                  scale,
                  width: '100%',
                  touchAction: 'none'
                }}
              >
                <StyledRowContainer
                  style={{
                    height: 80
                  }}
                >
                  <BodyBlock>
                    <RxDragHandleDots2
                      cursor={'grab'}
                      onMouseDown={() => setActiveRowIndex(true)}
                      onMouseUp={() => setActiveRowIndex(false)}
                    />
                  </BodyBlock>
                  <BodyBlock style={{ fontSize: 14, textAlign: 'left' }}>
                    nombre
                  </BodyBlock>
                  <BodyBlock style={{ position: 'relative' }}>fecha</BodyBlock>
                </StyledRowContainer>
              </animated.div>
            ))}
          </StyledTableContainer>
          </div>
          <div className="grupo-table">
          <StyledTableContainer>
            <h3 style={{ fontSize: '16px', marginBottom: '10px' }}>
              Grupo de envío 3
            </h3>
            <StyledRowContainerHeader>
              <HeadBlock>Mover</HeadBlock>
              <HeadBlock>Nombre</HeadBlock>
              <HeadBlock>Fecha</HeadBlock>
            </StyledRowContainerHeader>
            {springs.map(({ zIndex, shadow, y, scale }, i) => (
              <animated.div
                {...bind(i)}
                key={i}
                style={{
                  zIndex,
                  boxShadow: shadow.to(
                    (s) => `rgba(0, 0, 0, 0.15) 0px ${s}px ${2 * s}px 0px`
                  ),
                  y,
                  scale,
                  width: '100%',
                  touchAction: 'none'
                }}
              >
                <StyledRowContainer
                  style={{
                    height: 80
                  }}
                >
                  <BodyBlock>
                    <RxDragHandleDots2
                      cursor={'grab'}
                      onMouseDown={() => setActiveRowIndex(true)}
                      onMouseUp={() => setActiveRowIndex(false)}
                    />
                  </BodyBlock>
                  <BodyBlock style={{ fontSize: 14, textAlign: 'left' }}>
                    nombre
                  </BodyBlock>
                  <BodyBlock style={{ position: 'relative' }}>fecha</BodyBlock>
                </StyledRowContainer>
              </animated.div>
            ))}
          </StyledTableContainer>
          </div>
          <div className="grupo-table">
          <StyledTableContainer>
            <h3 style={{ fontSize: '16px', marginBottom: '10px' }}>
              Grupo de envío 4
            </h3>
            <StyledRowContainerHeader>
              <HeadBlock>Mover</HeadBlock>
              <HeadBlock>Nombre</HeadBlock>
              <HeadBlock>Fecha</HeadBlock>
            </StyledRowContainerHeader>
            {springs.map(({ zIndex, shadow, y, scale }, i) => (
              <animated.div
                {...bind(i)}
                key={i}
                style={{
                  zIndex,
                  boxShadow: shadow.to(
                    (s) => `rgba(0, 0, 0, 0.15) 0px ${s}px ${2 * s}px 0px`
                  ),
                  y,
                  scale,
                  width: '100%',
                  touchAction: 'none'
                }}
              >
                <StyledRowContainer
                  style={{
                    height: 80
                  }}
                >
                  <BodyBlock>
                    <RxDragHandleDots2
                      cursor={'grab'}
                      onMouseDown={() => setActiveRowIndex(true)}
                      onMouseUp={() => setActiveRowIndex(false)}
                    />
                  </BodyBlock>
                  <BodyBlock style={{ fontSize: 14, textAlign: 'left' }}>
                    nombre
                  </BodyBlock>
                  <BodyBlock style={{ position: 'relative' }}>fecha</BodyBlock>
                </StyledRowContainer>
              </animated.div>
            ))}
          </StyledTableContainer>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Prioridades
