import React from 'react'
import './Campain.css'

const Productos = () => {
  return (
    <div>
      <div className='d-flex justify-content-evenly align-items-center mt-3 mx-5'>
            <button className='regla_btn_gral'>
              <span className='m-2'>Regla</span>
            </button>
            <svg className='regla' xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
              <circle cx="12.5" cy="12.5" r="12.5" fill="#2BAE7E"/>
              <path d="M16.25 12.1122C16.2508 12.2445 16.2245 12.3757 16.1727 12.4982C16.1209 12.6207 16.0446 12.7322 15.9481 12.8261L11.7843 16.8484C11.5883 17.0378 11.3224 17.1442 11.0452 17.1442C10.768 17.1442 10.5022 17.0378 10.3061 16.8484C10.1101 16.6591 10 16.4023 10 16.1345C10 15.8667 10.1101 15.6099 10.3061 15.4205L13.7413 12.1122L10.3165 8.80382C10.146 8.61146 10.0569 8.36401 10.067 8.11093C10.0771 7.85785 10.1857 7.61778 10.3711 7.43869C10.5565 7.2596 10.805 7.15469 11.067 7.14492C11.329 7.13514 11.5852 7.22122 11.7843 7.38596L15.9481 11.4083C16.1404 11.5956 16.2488 11.8484 16.25 12.1122Z" fill="white"/>
            </svg>
            <button className='regla_btn_gral regla_btn_selected'>
              <span className='m-2'>Configuración</span>
            </button>
            <svg className='regla_btn_selected' xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
              <circle cx="12.5" cy="12.5" r="12.5" fill="#2BAE7E"/>
              <path d="M16.25 12.1122C16.2508 12.2445 16.2245 12.3757 16.1727 12.4982C16.1209 12.6207 16.0446 12.7322 15.9481 12.8261L11.7843 16.8484C11.5883 17.0378 11.3224 17.1442 11.0452 17.1442C10.768 17.1442 10.5022 17.0378 10.3061 16.8484C10.1101 16.6591 10 16.4023 10 16.1345C10 15.8667 10.1101 15.6099 10.3061 15.4205L13.7413 12.1122L10.3165 8.80382C10.146 8.61146 10.0569 8.36401 10.067 8.11093C10.0771 7.85785 10.1857 7.61778 10.3711 7.43869C10.5565 7.2596 10.805 7.15469 11.067 7.14492C11.329 7.13514 11.5852 7.22122 11.7843 7.38596L15.9481 11.4083C16.1404 11.5956 16.2488 11.8484 16.25 12.1122Z" fill="white"/>
            </svg>
            <button className='regla_btn_gral'>
              <span className='m-2'>Template</span>
            </button>
            <svg className='regla' xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
              <circle cx="12.5" cy="12.5" r="12.5" fill="#2BAE7E"/>
              <path d="M16.25 12.1122C16.2508 12.2445 16.2245 12.3757 16.1727 12.4982C16.1209 12.6207 16.0446 12.7322 15.9481 12.8261L11.7843 16.8484C11.5883 17.0378 11.3224 17.1442 11.0452 17.1442C10.768 17.1442 10.5022 17.0378 10.3061 16.8484C10.1101 16.6591 10 16.4023 10 16.1345C10 15.8667 10.1101 15.6099 10.3061 15.4205L13.7413 12.1122L10.3165 8.80382C10.146 8.61146 10.0569 8.36401 10.067 8.11093C10.0771 7.85785 10.1857 7.61778 10.3711 7.43869C10.5565 7.2596 10.805 7.15469 11.067 7.14492C11.329 7.13514 11.5852 7.22122 11.7843 7.38596L15.9481 11.4083C16.1404 11.5956 16.2488 11.8484 16.25 12.1122Z" fill="white"/>
            </svg>
            <button className='regla_btn_gral'>
              <span className='m-2'>Editor</span>
            </button>
            <svg className='regla' xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
              <circle cx="12.5" cy="12.5" r="12.5" fill="#2BAE7E"/>
              <path d="M16.25 12.1122C16.2508 12.2445 16.2245 12.3757 16.1727 12.4982C16.1209 12.6207 16.0446 12.7322 15.9481 12.8261L11.7843 16.8484C11.5883 17.0378 11.3224 17.1442 11.0452 17.1442C10.768 17.1442 10.5022 17.0378 10.3061 16.8484C10.1101 16.6591 10 16.4023 10 16.1345C10 15.8667 10.1101 15.6099 10.3061 15.4205L13.7413 12.1122L10.3165 8.80382C10.146 8.61146 10.0569 8.36401 10.067 8.11093C10.0771 7.85785 10.1857 7.61778 10.3711 7.43869C10.5565 7.2596 10.805 7.15469 11.067 7.14492C11.329 7.13514 11.5852 7.22122 11.7843 7.38596L15.9481 11.4083C16.1404 11.5956 16.2488 11.8484 16.25 12.1122Z" fill="white"/>
            </svg>
            <button className='regla_btn_gral'>
              <span className='m-2'>Resumen</span>
            </button>
      </div>
      <div className='regla_frame d-flex justify-content-center mt-3 mx-5'> 
          <div className='col_lf'>
            <h1>Tipo de Campaña</h1>
            <div>
              <h3>ÚNICA VEZ</h3>
              <span>Crear Campaña</span>
              <ul>
                <h3>AUTOMATIZADAS</h3>
                <li className='li_selected'>Welcome Kit</li>
                <li>Post Venta</li>
                <li>Carro Abandonado</li>
                <li>Remarketing</li>
                <li>Relacionamiento</li>
                <li>Recupero de Clientes</li>
                <li>Fidelidad</li>
                <li>Otras Campañas</li>
              </ul>
            </div>
          </div>
          <div className='col_rt'>
            <form>
              <div className='configuracion'>
                <h1 className='hor_line'>General</h1>
                <button className='config_btn_gral mr-2 regla_btn_selected'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                    <path d="M15.466 0.227481L0.842157 8.65538C-0.367125 9.35449 -0.251118 11.1426 1.04253 11.6731L5.06058 13.3348V16.2999C5.06058 17.9299 7.14167 18.6009 8.10488 17.3046L9.6446 15.2284L13.5783 16.8514C13.7857 16.9357 14.0036 16.9779 14.2216 16.9779C14.5099 16.9779 14.7946 16.9041 15.0512 16.7601C15.5012 16.5071 15.8105 16.0575 15.8914 15.5481L17.9795 1.94538C18.1939 0.536633 16.6823 -0.471624 15.466 0.227481ZM6.74795 16.2999V14.0304L8.03457 14.5609L6.74795 16.2999ZM14.2216 15.2916L8.81498 13.0608L13.7435 5.95382C14.1196 5.40929 13.4095 4.77693 12.9104 5.20904L5.47539 11.6837L1.68584 10.1168L16.3097 1.68541L14.2216 15.2916Z" fill="#F29718"/>
                  </svg>
                  <span className='m-2'>EMAIL</span>
                </button>
                <button className='config_btn_gral'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="18" viewBox="0 0 21 18" fill="none">
                    <path d="M5.78838 7.07143C5.07722 7.07143 4.50267 7.64598 4.50267 8.35714C4.50267 9.0683 5.07722 9.64286 5.78838 9.64286C6.49954 9.64286 7.0741 9.0683 7.0741 8.35714C7.0741 7.64598 6.49954 7.07143 5.78838 7.07143ZM10.2884 7.07143C9.57722 7.07143 9.00267 7.64598 9.00267 8.35714C9.00267 9.0683 9.57722 9.64286 10.2884 9.64286C10.9995 9.64286 11.5741 9.0683 11.5741 8.35714C11.5741 7.64598 10.9995 7.07143 10.2884 7.07143ZM14.7884 7.07143C14.0772 7.07143 13.5027 7.64598 13.5027 8.35714C13.5027 9.0683 14.0772 9.64286 14.7884 9.64286C15.4995 9.64286 16.0741 9.0683 16.0741 8.35714C16.0741 7.64598 15.4995 7.07143 14.7884 7.07143ZM10.2884 0C4.60713 0 0.00266658 3.74062 0.00266658 8.35714C0.00266658 10.2696 0.80222 12.0214 2.12811 13.4317C1.52945 15.0147 0.283917 16.3567 0.263827 16.3728C-0.00135128 16.654 -0.0736727 17.0638 0.0790059 17.4174C0.231684 17.771 0.581238 18 0.966952 18C3.43793 18 5.3866 16.9674 6.55579 16.1397C7.71695 16.5054 8.97052 16.7143 10.2884 16.7143C15.9696 16.7143 20.5741 12.9737 20.5741 8.35714C20.5741 3.74062 15.9696 0 10.2884 0ZM10.2884 14.7857C9.21561 14.7857 8.1549 14.621 7.13838 14.2996L6.22633 14.0103L5.44285 14.5647C4.86829 14.9705 4.08079 15.4246 3.13258 15.7299C3.42588 15.2437 3.71115 14.6973 3.93213 14.1147L4.35802 12.9857L3.53035 12.1098C2.80311 11.3344 1.93124 10.0527 1.93124 8.35714C1.93124 4.81339 5.6799 1.92857 10.2884 1.92857C14.8969 1.92857 18.6455 4.81339 18.6455 8.35714C18.6455 11.9009 14.8969 14.7857 10.2884 14.7857Z" fill="#F29718"/>
                  </svg>
                  <span className='m-2'>SMS</span>
                </button>
                  <>
                    <h2 className='mt-3'>Regla de disparo</h2>
                    <span>Welcome Kit</span>
                  </>
                  <>
                    <h2 className='mt-3'>Nombra esta campaña</h2>
                    <div className='config_claim'>Sólo para uso interno. Ej.: “NewsletterAbril”</div>
                    <input placeholder='Buscar por nombre  o asunto' className='mt-2'></input>
                    <div className='config_claim text-right mr-6'>250 caracteres restantes</div>
                  </>
                  <>
                    <h2 className='mt-3'>Seleccione una carpeta</h2>
                    <div className='config_claim'>Te ayudará a ordenar mejor tu carpeta.</div>
                    <select className='mt-2'>
                      <option>Seleccione una carpeta</option>
                    </select>
                  </>
                  <>
                    <h2 className='mt-3'>Seleccione un segmento
                      <svg className='ml-2 mb-1' xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path d="M13.5 10H12.5C12.3674 10 12.2402 10.0527 12.1464 10.1464C12.0527 10.2402 12 10.3674 12 10.5V14H2V4H6.5C6.63261 4 6.75979 3.94732 6.85355 3.85355C6.94732 3.75979 7 3.63261 7 3.5V2.5C7 2.36739 6.94732 2.24021 6.85355 2.14645C6.75979 2.05268 6.63261 2 6.5 2H1.5C1.10218 2 0.720644 2.15804 0.43934 2.43934C0.158035 2.72064 0 3.10218 0 3.5L0 14.5C0 14.8978 0.158035 15.2794 0.43934 15.5607C0.720644 15.842 1.10218 16 1.5 16H12.5C12.8978 16 13.2794 15.842 13.5607 15.5607C13.842 15.2794 14 14.8978 14 14.5V10.5C14 10.3674 13.9473 10.2402 13.8536 10.1464C13.7598 10.0527 13.6326 10 13.5 10ZM15.25 0H11.25C10.5822 0 10.2484 0.809687 10.7188 1.28125L11.8353 2.39781L4.21875 10.0116C4.14883 10.0812 4.09335 10.164 4.0555 10.2552C4.01764 10.3464 3.99816 10.4441 3.99816 10.5428C3.99816 10.6415 4.01764 10.7393 4.0555 10.8304C4.09335 10.9216 4.14883 11.0044 4.21875 11.0741L4.92719 11.7812C4.99687 11.8512 5.07966 11.9066 5.17082 11.9445C5.26199 11.9824 5.35973 12.0018 5.45844 12.0018C5.55715 12.0018 5.65489 11.9824 5.74605 11.9445C5.83721 11.9066 5.92001 11.8512 5.98969 11.7812L13.6025 4.16625L14.7188 5.28125C15.1875 5.75 16 5.42188 16 4.75V0.75C16 0.551088 15.921 0.360322 15.7803 0.21967C15.6397 0.0790176 15.4489 0 15.25 0Z" fill="#10A36C"/>
                      </svg>
                    </h2>
                    <div className='config_claim'>Segmentar la campaña aumenta el % de click en un 14% aprox. contra campañas no segmentadas.</div>
                    <select className='mt-2'>
                      <option>Sin segmentación</option>
                    </select>
                  </>
                  <>
                    <h2 className='mt-3'>Período de medición de resultados</h2>
                    <div className='config_claim'>Las ventas realizadas en este período se asociarán a esta campaña siempre y cuando el clliente haya abierto el e-mail previamente.</div>
                    <select className='dos mt-2'>
                      <option>3</option>
                    </select>
                    <span className='ml-2'>días desde el envío.</span>
                  </>
                  <>
                    <h2 className='mt-3'>Condición de disparo</h2>
                    <div className='config_claim'>Esta condición activará el envío de la campaña.</div>
                    <input placeholder='1' className='dos mt-2'></input>
                    <select className='dos mt-2 ml-2'>
                      <option>día</option>
                    </select>
                    <span className='ml-2'>después de</span>
                    <span className='etiq_color ml-2'>Creación del cliente</span>
                    <span className='ml-2'>a las</span>
                    <select className='dos mt-2 ml-2'>
                      <option>15 hs.</option>
                    </select>
                  </>
                  <>
                    <h2 className='mt-3'>Frequency CAP</h2>
                    <div className='config_claim mb-1'>Se ignora el CAP para esta campaña, al momento de enviarse no tendrá en cuenta el límite de envíos que un cliente puede recibir.</div>
                    <span>Ingonar Frequency CAP</span>
                  </>
              </div>
              <div className='configuracion mt-6'>
                <h1 className='hor_line'>Configuración de E-mail</h1>
                <>
                    <h2 className='mt-3'>Asunto</h2>
                    <div className='config_claim'>Un buen asunto incrementa el % de apertura. No te olvides de personalizarlo.</div>
                    <input placeholder='' className='tres mt-2'></input>
                    <select className='dos mt-2 ml-2'>
                      <option>Campos dinámicos</option>
                    </select>                 
                  </>
                  <>
                    <h2 className='mt-3'>Vista preliminar del texto</h2>
                    <div className='config_claim'>Este texto aparece en la bandeja de entrada después del asunto</div>
                    <input placeholder='Buscar por nombre  o asunto' className='mt-2'></input>
                    <div className='config_claim text-right mr-6'>150 caracteres restantes</div>
                  </>
                  <>
                    <h2 className='mt-3'>De:</h2>
                    <div className='config_claim'>Datos del emisor (opcional). Si deja esta opción vacía se enviará con los datos de la configuración.</div>
                    <span className='mr-2'>E-mail:</span>
                    <input placeholder='hola@nombre.com' className='tres mt-2'></input>
                    <span className='ml-2 mr-2'>Nombre:</span>
                    <input placeholder='NombreEmpresa' className='tres mt-2'></input>
                  </>
                  <>
                    <h2 className='mt-3'>Campos UTM</h2>
                    <div className='config_claim'>Se ignorarán los caracteres especiales.</div>
                    <span className='mr-2'>Source:</span>
                    <input placeholder='BrainDW' className='tres mt-2'></input>
                    <span className='ml-2 mr-2'>Campaign</span>
                    <input className='tres mt-2'></input>
                    <span className='ml-2 mr-2'>Medium</span>
                    <input placeholder='email' className='tres mt-2'></input>
                  </>
              </div>
              <div className='d-flex justify-content-between mt-5'>
                <button className='config_btn_gral mr-2'>
                    <span className='m-2'>Atrás</span>
                </button>
                <button className='config_btn_gral mr-2'>
                    <span className='m-2'>Guardar</span>
                </button>
                <button className='config_btn_gral mr-2 regla_btn_selected'>
                    <span className='m-2'>Siguiente</span>
                </button>
              </div>
            </form>          
          </div>
      </div>
    </div>
  )
}

export default Productos
