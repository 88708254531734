import React from 'react'
import { MenuContainer } from '../Menu'
import MenuOptions from '../Menu/MenuOptions'
import { subCampainOptions, subCampainOptionsMusimundo } from '../../utils/secondMenuOptions'

const SubMenuCampain = () => {
  const handleAction = () => {
    console.log()
  }

  const client: any = (localStorage.getItem('clientSelected'))

  const clientSelected = JSON.parse(client)

  return (
    <>
      <MenuContainer>
        <MenuOptions
          tag="BrainDW | Campaña"
          options={clientSelected.clientName !== 'musimundo_hybris' ? subCampainOptions : subCampainOptionsMusimundo}
          onAction={handleAction}
        />
      </MenuContainer>
    </>
  )
}

export default SubMenuCampain