import React from 'react'
import './Segmentos.css'
import { AiOutlineCheck } from 'react-icons/ai'

const Segmentos = () => {
  return (
    <div>
      <div className="d-flex justify-content-end align-items-center mt-3 mr-5">
        <div className="col-md-12 container mb-3">
          <div >
            <div className="cfa-ecommerce-header">
              <h1>Comercio Electrónico</h1>
            </div>

            <div className="cfa-ecommerce-content d-flex justify-content-between">
              <div className="cfa-compras">
                <h3 className="cfa-ecommerce-title">Compras</h3>
                <ul className="cfa-ecommerce-list">
                  <li>
                    <div>Compras Totales:</div> <div><span>1</span></div>
                  </li>
                  <li>
                    
                    <div>Importe total de compras: </div>
                    <div><span>$ 133.000</span></div>
                  </li>
                  <li>
                    <div>
                    Importe medio de compras: 
                    </div>
                    <div>
                    <span>$ 133.000</span>

                    </div>
                  </li>
                  <li>
                    <div>
                    Fecha de primera compra:{' '}

                    </div>
                    <div>
                    <span>5 de Mayo 2024, 15:30 hs.</span>

                    </div>
                  </li>
                  <li>
                    <div>
                    Fecha de última compra:{' '}

                    </div>
                    <div>
                    <span>5 de Mayo 2024, 16:00 hs.</span>

                    </div>
                  </li>
                </ul>
              </div>

              <div className="cfa-carrito-actual">
                <h3 className="cfa-ecommerce-title">Carrito Actual</h3>
                <ul className="cfa-ecommerce-list">
                  <li>
                    <div>
                    <strong>Estado del carrito:</strong>

                    </div>
                    <div>
                    <span className="cfa-carrito-estado">Abandonado</span>

                    </div>
                  </li>
                  <li>
                    <div>
                    <strong>Última actualización:</strong>

                    </div>
                    <div>
                    <span>5 de Mayo 2024, 15:30 hs.</span>

                    </div>
                  </li>
                  <li>
                    
                    <div>
                    <a href="#">COCINA ESCORIAL CANDOR BLACK</a>
                    </div>
                    <div>
                    <strong>Cantidad: 1</strong>
                    </div>
                    <div>
                    <strong>Precio: $ 133.000</strong>
                    </div>
                  </li>
                  <li>
                    <div>
                    <strong>Resumen del carrito:</strong>

                    </div>
                    <div>
                    <span>Cantidad total: 1</span> -{' '}

                    </div>
                    <div>
                    <span>Precio Total: $ 133.000</span>

                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <button className="cfa-button-aplicar mb-3">
              <span className="mr-2">
                <AiOutlineCheck />
              </span>
              Aplicar
            </button>

            <div className="cfa-segmentos-container">
              <div className="cfa-segmentos-header d-flex align-items-center justify-content-between">
                <h1 className="cfa-segmentos-title">Segmentos</h1>
                <div className="d-flex">
                  <div className="cfa-select-segmento">
                    <select>
                      <option>Seleccionar Segmento</option>
                    </select>
                  </div>
                  <button className="cfa-button-anadir-segmento">
                    Añadir Segmento
                  </button>
                </div>
              </div>

              <table className="cfa-segmentos-table">
                <thead>
                  <tr>
                    <th>Nombre</th>
                    <th>Miembro Desde</th>
                    <th>Tipo</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>No compraron Heladeras/ Cocinas/ Lavarropas</td>
                    <td>5 de Mayo de 2024, 11:30 hs.</td>
                    <td>
                      <span className="cfa-dot cfa-dot-dinamico"></span>{' '}
                      Dinámico
                    </td>
                  </tr>
                  <tr>
                    <td>Compradores</td>
                    <td>5 de Mayo de 2024, 11:30 hs.</td>
                    <td>
                      <span className="cfa-dot cfa-dot-dinamico-plus"></span>{' '}
                      Dinámico Plus
                    </td>
                  </tr>
                  <tr>
                    <td>Abandono de carrito</td>
                    <td>5 de Mayo de 2024, 11:30 hs.</td>
                    <td>
                      <span className="cfa-dot cfa-dot-dinamico-plus"></span>{' '}
                      Dinámico Plus
                    </td>
                  </tr>
                  <tr>
                    <td>Abandono de carrito</td>
                    <td>5 de Mayo de 2024, 11:30 hs.</td>
                    <td>
                      <span className="cfa-dot cfa-dot-dinamico"></span>{' '}
                      Dinámico
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Segmentos
